import "./index.scss";
import { Elm } from "./Main.elm";
import Swal from 'sweetalert2'
import initOAuth, { registerPorts } from "./Login/auth"

console.log(ENV);

initOAuth().then((tokens) => {

    const app = Elm.Main.init({
        node: document.getElementById("app"),
        flags: {
            apiUrl: API_URL,
            fipCode: FIP_CODE,
            currentTime: Date.now(),
            tokens
        }
    });

    app.ports.successAlert.subscribe(({ message, title }) => Swal.fire(title, message, "success"))

    app.ports.errorAlert.subscribe(({ message, title }) =>
        Swal.fire({
            title,
            text: message,
            icon: "error",
            onOpen: () => Swal.hideLoading()
        })
    )

    app.ports.showLoader.subscribe(() =>
        Swal.fire({
            allowOutsideClick: false,
            showConfirmButton: false,
            onOpen: () => Swal.showLoading(),
        })
    )

    app.ports.closeAlert.subscribe(() => Swal.close())

    registerPorts(app)
})


