let tokenStr = localStorage.getItem("token");
let token;
try {
    token = JSON.parse(tokenStr)
} catch {
    token = null
}

export default ({ facebook, google } = {}) => {
    const oauths = []
    if (facebook) {
        oauths.push(facebookAuth(facebook))
    }
    if (google) {
        oauths.push(googleAuth(facebook))
    }
    return Promise.all(oauths)
        .then(responses => JSON.stringify({
            token,
            facebook: responses[0] || null,
            google: responses[1] || null
        }))
}
export const registerPorts = (app) => {
    app.ports.storeToken.subscribe((token) => {
        localStorage.setItem("token", JSON.stringify(token))
    })

    app.ports.logoutAll.subscribe(() => {
        localStorage.clear("token")
    })
}
