(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.aq.M === region.aB.M)
	{
		return 'on line ' + region.aq.M;
	}
	return 'on lines ' + region.aq.M + ' through ' + region.aB.M;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bJ,
		impl.ce,
		impl.b8,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		t: func(record.t),
		ar: record.ar,
		an: record.an
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.t;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ar;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.an) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bJ,
		impl.ce,
		impl.b8,
		function(sendToApp, initialModel) {
			var view = impl.cg;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bJ,
		impl.ce,
		impl.b8,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.ap && impl.ap(sendToApp)
			var view = impl.cg;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bn);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ba) && (_VirtualDom_doc.title = title = doc.ba);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.bS;
	var onUrlRequest = impl.bT;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		ap: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bX === next.bX
							&& curr.aJ === next.aJ
							&& curr.aT.a === next.aT.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		bJ: function(flags)
		{
			return A3(impl.bJ, flags, _Browser_getUrl(), key);
		},
		cg: impl.cg,
		ce: impl.ce,
		b8: impl.b8
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { bG: 'hidden', bp: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { bG: 'mozHidden', bp: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { bG: 'msHidden', bp: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { bG: 'webkitHidden', bp: 'webkitvisibilitychange' }
		: { bG: 'hidden', bp: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		a3: _Browser_getScene(),
		bd: {
			bf: _Browser_window.pageXOffset,
			bg: _Browser_window.pageYOffset,
			be: _Browser_doc.documentElement.clientWidth,
			aG: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		be: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		aG: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			a3: {
				be: node.scrollWidth,
				aG: node.scrollHeight
			},
			bd: {
				bf: node.scrollLeft,
				bg: node.scrollTop,
				be: node.clientWidth,
				aG: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			a3: _Browser_getScene(),
			bd: {
				bf: x,
				bg: y,
				be: _Browser_doc.documentElement.clientWidth,
				aG: _Browser_doc.documentElement.clientHeight
			},
			bx: {
				bf: x + rect.left,
				bg: y + rect.top,
				be: rect.width,
				aG: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.by.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.by.b, xhr)); });
		$elm$core$Maybe$isJust(request.cb) && _Http_track(router, xhr, request.cb.a);

		try {
			xhr.open(request.bO, request.cf, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.cf));
		}

		_Http_configureRequest(xhr, request);

		request.bn.a && xhr.setRequestHeader('Content-Type', request.bn.a);
		xhr.send(request.bn.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bF; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.b9.a || 0;
	xhr.responseType = request.by.d;
	xhr.withCredentials = request.bj;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cf: xhr.responseURL,
		b4: xhr.status,
		b5: xhr.statusText,
		bF: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			b1: event.loaded,
			a5: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			bZ: event.loaded,
			a5: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}





// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.bP) { flags += 'm'; }
	if (options.bo) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $author$project$Types$LLoginMsg = $elm$core$Basics$identity;
var $author$project$Types$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$LoginMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Types$UrlChanged = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.g) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.i),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.i);
		} else {
			var treeLen = builder.g * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.g);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.i) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.i);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{j: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, i: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {aF: fragment, aJ: host, aR: path, aT: port_, bX: protocol, aY: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Types$AdjustTimeZone = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Route$Home = {$: 2};
var $author$project$Types$IEGotComplaint = function (a) {
	return {$: 27, a: a};
};
var $author$project$Types$InsertEditMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Login = {$: 0};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.b4));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {a_: reqs, a7: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.cb;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.a_));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.a7)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					bj: r.bj,
					bn: r.bn,
					by: A2(_Http_mapExpect, func, r.by),
					bF: r.bF,
					bO: r.bO,
					b9: r.b9,
					cb: r.cb,
					cf: r.cf
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{bj: false, bn: r.bn, by: r.by, bF: r.bF, bO: r.bO, b9: r.b9, cb: r.cb, cf: r.cf}));
};
var $author$project$Api$request = F5(
	function (method, token, url, body, expect) {
		var headers = $elm$core$String$isEmpty(token) ? _List_Nil : _List_fromArray(
			[
				A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
			]);
		return $elm$http$Http$request(
			{bn: body, by: expect, bF: headers, bO: method, b9: $elm$core$Maybe$Nothing, cb: $elm$core$Maybe$Nothing, cf: url});
	});
var $author$project$Api$get = F3(
	function (apiUrl, token, expect) {
		return A5($author$project$Api$request, 'GET', token, apiUrl, $elm$http$Http$emptyBody, expect);
	});
var $author$project$Types$Response = F3(
	function (status, data, message) {
		return {bv: data, t: message, b3: status};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Api$responseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Types$Response,
	A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(
				$elm$json$Json$Encode$object(_List_Nil)),
			$elm$json$Json$Decode$succeed),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
	A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string));
var $author$project$Api$getComplaint = F4(
	function (apiUrl, token, id, expectMsg) {
		return A3(
			$author$project$Api$get,
			apiUrl + ('/complaint/' + id),
			token,
			A2($elm$http$Http$expectJson, expectMsg, $author$project$Api$responseDecoder));
	});
var $author$project$Types$HGotComplaints = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$HomeMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$getComplaints = F2(
	function (apiUrl, token) {
		return A3(
			$author$project$Api$get,
			apiUrl + '/complaint',
			token,
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HGotComplaints),
				$author$project$Api$responseDecoder));
	});
var $author$project$Types$HNone = {$: 0};
var $author$project$Loadable$Loading = {$: 0};
var $author$project$HomePage$init = {
	aw: {cc: 0, ci: 0},
	A: $author$project$Loadable$Loading,
	_: $author$project$Types$HNone,
	P: $elm$core$Maybe$Nothing,
	Q: ''
};
var $author$project$InsertEditPage$initComplaint = {
	aw: {cc: 0, ci: 0},
	bv: $elm$core$Dict$empty,
	aK: '',
	bX: ''
};
var $author$project$InsertEditPage$init = {d: $author$project$InsertEditPage$initComplaint, V: 1, Y: false, Z: $elm$core$Maybe$Nothing, _: false};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Route$routeToString = function (page) {
	var pieces = function () {
		switch (page.$) {
			case 2:
				return _List_fromArray(
					['']);
			case 0:
				return _List_fromArray(
					['entrar']);
			case 1:
				return _List_fromArray(
					['cadastrar']);
			case 3:
				return _List_fromArray(
					['criar']);
			case 4:
				return _List_fromArray(
					['alterar-senha']);
			case 5:
				var id = page.a;
				return _List_fromArray(
					[id]);
			default:
				return _List_fromArray(
					['nao-encontrada']);
		}
	}();
	return '/' + A2($elm$core$String$join, '/', pieces);
};
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Helpers$redirectIfToken = F3(
	function (maybeToken, withToken, withoutToken) {
		if (!maybeToken.$) {
			var token = maybeToken.a;
			return withToken(token);
		} else {
			return withoutToken;
		}
	});
var $author$project$Main$getInitData = F4(
	function (apiUrl, route, key, maybeToken) {
		var insertEditInit = $author$project$InsertEditPage$init;
		var defaultInitData = {z: $elm$core$Platform$Cmd$none, aI: $author$project$HomePage$init, ah: insertEditInit};
		var redirectIfLoggedIn = function (data) {
			return A3(
				$author$project$Helpers$redirectIfToken,
				maybeToken,
				$elm$core$Basics$always(
					_Utils_update(
						defaultInitData,
						{
							z: A2($author$project$Route$pushUrl, key, $author$project$Route$Home)
						})),
				data);
		};
		var redirectIfLoggedOut = function (data) {
			return A3(
				$author$project$Helpers$redirectIfToken,
				maybeToken,
				data,
				_Utils_update(
					defaultInitData,
					{
						z: A2($author$project$Route$pushUrl, key, $author$project$Route$Login)
					}));
		};
		switch (route.$) {
			case 2:
				return redirectIfLoggedOut(
					function (t) {
						return _Utils_update(
							defaultInitData,
							{
								z: A2($author$project$Api$getComplaints, apiUrl, t.ca)
							});
					});
			case 3:
				return redirectIfLoggedOut(
					function (t) {
						return _Utils_update(
							defaultInitData,
							{
								z: A2($author$project$Api$getComplaints, apiUrl, t.ca)
							});
					});
			case 5:
				var id = route.a;
				return redirectIfLoggedOut(
					function (t) {
						return _Utils_update(
							defaultInitData,
							{
								z: $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A4(
											$author$project$Api$getComplaint,
											apiUrl,
											t.ca,
											id,
											A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEGotComplaint)),
											A2($author$project$Api$getComplaints, apiUrl, t.ca)
										])),
								ah: _Utils_update(
									insertEditInit,
									{Y: true})
							});
					});
			case 4:
				return redirectIfLoggedOut(
					$elm$core$Basics$always(defaultInitData));
			case 0:
				return redirectIfLoggedIn(defaultInitData);
			case 1:
				return redirectIfLoggedIn(defaultInitData);
			default:
				return defaultInitData;
		}
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$ChangePasswordPage$init = {G: '', t: '', N: '', O: '', _: false};
var $author$project$Login$Login$init = function (apiUrl) {
	return {bk: apiUrl, p: '', t: $elm$core$Maybe$Nothing, O: '', _: false};
};
var $author$project$LoginPage$init = function (apiUrl) {
	return {
		W: $author$project$Login$Login$init(apiUrl)
	};
};
var $author$project$SignUpPage$init = {G: '', t: '', O: '', _: false, E: ''};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Login$Login$isTokenValid = F2(
	function (token, now) {
		return _Utils_cmp(token.bv.ac, now) > 0;
	});
var $author$project$Login$Login$Tokens = F3(
	function (token, facebook, google) {
		return {bB: facebook, bE: google, ca: token};
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Login$Login$OAuthToken = F4(
	function (token, email, name, profilePictureUrl) {
		return {p: email, bQ: name, aX: profilePictureUrl, ca: token};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Login$Login$oauthDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Login$Login$OAuthToken,
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'profilePictureUrl', $elm$json$Json$Decode$string));
var $author$project$Login$Login$Token = F2(
	function (token, data) {
		return {bv: data, ca: token};
	});
var $author$project$Login$Login$TokenData = F2(
	function (email, expiration) {
		return {p: email, ac: expiration};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Login$Login$tokenDataDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Login$Login$TokenData,
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'expiration', $elm$json$Json$Decode$int));
var $author$project$Login$Login$tokenDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Login$Login$Token,
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'data', $author$project$Login$Login$tokenDataDecoder));
var $author$project$Login$Login$tokensDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Login$Login$Tokens,
	A2(
		$elm$json$Json$Decode$field,
		'token',
		$elm$json$Json$Decode$nullable($author$project$Login$Login$tokenDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'facebook',
		$elm$json$Json$Decode$nullable($author$project$Login$Login$oauthDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'google',
		$elm$json$Json$Decode$nullable($author$project$Login$Login$oauthDecoder)));
var $author$project$Login$Login$initTokens = F2(
	function (tokensString, now) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$Login$Login$tokensDecoder, tokensString);
		if (!_v0.$) {
			var tokens = _v0.a;
			var newToken = A2(
				$elm$core$Maybe$andThen,
				function (token) {
					return A2($author$project$Login$Login$isTokenValid, token, now) ? $elm$core$Maybe$Just(token) : $elm$core$Maybe$Nothing;
				},
				tokens.ca);
			if (!newToken.$) {
				var token = newToken.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(token),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Route$NotFound = {$: 6};
var $author$project$Route$ChangePassword = {$: 4};
var $author$project$Route$Edit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Route$Insert = {$: 3};
var $author$project$Route$SignUp = {$: 1};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {B: frag, C: params, y: unvisited, v: value, F: visited};
	});
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.F;
		var unvisited = _v0.y;
		var params = _v0.C;
		var frag = _v0.B;
		var value = _v0.v;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.F;
			var unvisited = _v1.y;
			var params = _v1.C;
			var frag = _v1.B;
			var value = _v1.v;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.F;
		var unvisited = _v0.y;
		var params = _v0.C;
		var frag = _v0.B;
		var value = _v0.v;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.F;
			var unvisited = _v0.y;
			var params = _v0.C;
			var frag = _v0.B;
			var value = _v0.v;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$matchers = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Insert,
			$elm$url$Url$Parser$s('criar')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			$elm$url$Url$Parser$s('entrar')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SignUp,
			$elm$url$Url$Parser$s('cadastrar')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ChangePassword,
			$elm$url$Url$Parser$s('alterar-senha')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NotFound,
			$elm$url$Url$Parser$s('nao-encontrada')),
			A2($elm$url$Url$Parser$map, $author$project$Route$Edit, $elm$url$Url$Parser$string)
		]));
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.y;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.v);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.v);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.aR),
					$elm$url$Url$Parser$prepareQuery(url.aY),
					url.aF,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$parseUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Route$NotFound,
		A2($elm$url$Url$Parser$parse, $author$project$Route$matchers, url));
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var route = $author$project$Route$parseUrl(url);
		var _v0 = A2($author$project$Login$Login$initTokens, flags.bb, flags.ax);
		var maybeToken = _v0.a;
		var loginCmd = _v0.b;
		var initData = A4($author$project$Main$getInitData, flags.bk, route, key, maybeToken);
		return _Utils_Tuple2(
			{
				bk: flags.bk,
				aa: $author$project$ChangePasswordPage$init,
				bC: flags.bC,
				k: initData.aI,
				c: initData.ah,
				aO: key,
				aj: $author$project$LoginPage$init(flags.bk),
				ao: route,
				b2: $author$project$SignUpPage$init,
				ca: maybeToken,
				bh: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						initData.z,
						A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeL, $author$project$Types$LoginMsg, $elm$core$Basics$identity),
						loginCmd),
						A2($elm$core$Task$perform, $author$project$Types$AdjustTimeZone, $elm$time$Time$here)
					])));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Login$Login$GotFacebookLogin = function (a) {
	return {$: 6, a: a};
};
var $author$project$Login$Login$GotGoogleToken = function (a) {
	return {$: 7, a: a};
};
var $author$project$Login$Login$GotLoggedOut = function (a) {
	return {$: 9, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Login$Login$gotFacebookLogin = _Platform_incomingPort(
	'gotFacebookLogin',
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (profilePictureUrl) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (name) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (email) {
									return $elm$json$Json$Decode$succeed(
										{p: email, bQ: name, aX: profilePictureUrl, ca: token});
								},
								A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'profilePictureUrl', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)));
var $author$project$Login$Login$gotGoogleToken = _Platform_incomingPort(
	'gotGoogleToken',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
			])));
var $author$project$Login$Login$loggedOut = _Platform_incomingPort(
	'loggedOut',
	$elm$json$Json$Decode$null(0));
var $author$project$Login$Login$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Login$Login$gotFacebookLogin($author$project$Login$Login$GotFacebookLogin),
			$author$project$Login$Login$gotGoogleToken($author$project$Login$Login$GotGoogleToken),
			$author$project$Login$Login$loggedOut($author$project$Login$Login$GotLoggedOut)
		]));
var $author$project$Types$Alert = F2(
	function (title, message) {
		return {t: message, ba: title};
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Helpers$closeAlert = _Platform_outgoingPort(
	'closeAlert',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Helpers$errorAlert = _Platform_outgoingPort(
	'errorAlert',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'message',
					$elm$json$Json$Encode$string($.t)),
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string($.ba))
				]));
	});
var $author$project$Types$GotBrokers = function (a) {
	return {$: 12, a: a};
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Api$getBody = function (response) {
	switch (response.$) {
		case 0:
			var url = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadUrl(url));
		case 1:
			return $elm$core$Result$Err($elm$http$Http$Timeout);
		case 2:
			return $elm$core$Result$Err($elm$http$Http$NetworkError);
		case 3:
			var metadata = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadStatus(metadata.b4));
		default:
			var body = response.b;
			return $elm$core$Result$Ok(body);
	}
};
var $author$project$Api$getBrokers = F2(
	function (apiUrl, token) {
		return A3(
			$author$project$Api$get,
			apiUrl + '/brokers',
			token,
			A2($elm$http$Http$expectBytesResponse, $author$project$Types$GotBrokers, $author$project$Api$getBody));
	});
var $author$project$Types$NoOp = {$: 0};
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Main$handleUrlChanged = F2(
	function (route, model) {
		var insertEditModel = model.c;
		var homeModel = model.k;
		var redirectIfNotToken = function (data) {
			return A3(
				$author$project$Helpers$redirectIfToken,
				model.ca,
				data,
				_Utils_Tuple2(
					model,
					A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)));
		};
		var _v0 = function () {
			switch (route.$) {
				case 2:
					return redirectIfNotToken(
						function (t) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										k: _Utils_update(
											homeModel,
											{A: $author$project$Loadable$Loading})
									}),
								A2($author$project$Api$getComplaints, model.bk, t.ca));
						});
				case 3:
					return redirectIfNotToken(
						$elm$core$Basics$always(
							_Utils_Tuple2(
								_Utils_update(
									model,
									{c: $author$project$InsertEditPage$init}),
								$elm$core$Platform$Cmd$none)));
				case 5:
					var id = route.a;
					return redirectIfNotToken(
						function (t) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										c: _Utils_update(
											insertEditModel,
											{Y: true})
									}),
								A4(
									$author$project$Api$getComplaint,
									model.bk,
									t.ca,
									id,
									A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEGotComplaint)));
						});
				case 4:
					return redirectIfNotToken(
						$elm$core$Basics$always(
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
				case 0:
					return A3(
						$author$project$Helpers$redirectIfToken,
						model.ca,
						$elm$core$Basics$always(
							_Utils_Tuple2(
								model,
								A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Home))),
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				case 1:
					return A3(
						$author$project$Helpers$redirectIfToken,
						model.ca,
						$elm$core$Basics$always(
							_Utils_Tuple2(
								model,
								A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Home))),
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}();
		var newModel = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Types$NoOp),
						A2($elm$browser$Browser$Dom$setViewport, 0, 0))
					])));
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Login$Login$logoutAll = _Platform_outgoingPort(
	'logoutAll',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Login$Login$logout = $author$project$Login$Login$logoutAll(0);
var $author$project$Helpers$showLoader = _Platform_outgoingPort(
	'showLoader',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Types$CPGotChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$ChangePasswordMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$changePasswordEncoder = F2(
	function (password, newPassword) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password)),
					_Utils_Tuple2(
					'newPassword',
					$elm$json$Json$Encode$string(newPassword))
				]));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Api$post = F4(
	function (apiUrl, token, body, expect) {
		return A5($author$project$Api$request, 'POST', token, apiUrl, body, expect);
	});
var $author$project$Api$postChangePassword = F4(
	function (apiUrl, token, password, newPassword) {
		return A4(
			$author$project$Api$post,
			apiUrl + '/auth/change-password',
			token,
			$elm$http$Http$jsonBody(
				A2($author$project$Api$changePasswordEncoder, password, newPassword)),
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$ChangePasswordMsg, $author$project$Types$CPGotChanged),
				$author$project$Api$responseDecoder));
	});
var $author$project$ChangePasswordPage$updateChangePasswordModel = F2(
	function (model, changePasswordModel) {
		return _Utils_update(
			model,
			{aa: changePasswordModel});
	});
var $author$project$ChangePasswordPage$update = F3(
	function (msg, model, token) {
		var changePasswordModel = model.aa;
		switch (msg.$) {
			case 0:
				var password = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$ChangePasswordPage$updateChangePasswordModel,
						model,
						_Utils_update(
							changePasswordModel,
							{O: password})),
					$elm$core$Platform$Cmd$none);
			case 1:
				var newPassword = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$ChangePasswordPage$updateChangePasswordModel,
						model,
						_Utils_update(
							changePasswordModel,
							{N: newPassword})),
					$elm$core$Platform$Cmd$none);
			case 2:
				var confirmPassword = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$ChangePasswordPage$updateChangePasswordModel,
						model,
						_Utils_update(
							changePasswordModel,
							{G: confirmPassword})),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_eq(changePasswordModel.N, changePasswordModel.G) ? _Utils_Tuple2(
					A2(
						$author$project$ChangePasswordPage$updateChangePasswordModel,
						model,
						_Utils_update(
							changePasswordModel,
							{t: '', _: true})),
					A4($author$project$Api$postChangePassword, model.bk, token.ca, changePasswordModel.O, changePasswordModel.G)) : _Utils_Tuple2(
					A2(
						$author$project$ChangePasswordPage$updateChangePasswordModel,
						model,
						_Utils_update(
							changePasswordModel,
							{t: 'Senhas não conferem'})),
					$elm$core$Platform$Cmd$none);
			default:
				var responseResult = msg.a;
				var newChangePasswordModel = _Utils_update(
					changePasswordModel,
					{_: false});
				if (!responseResult.$) {
					var response = responseResult.a;
					return response.b3 ? _Utils_Tuple2(
						A2(
							$author$project$ChangePasswordPage$updateChangePasswordModel,
							model,
							_Utils_update(
								newChangePasswordModel,
								{G: '', t: 'Senha alterada com sucesso', N: '', O: ''})),
						$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
						A2(
							$author$project$ChangePasswordPage$updateChangePasswordModel,
							model,
							_Utils_update(
								newChangePasswordModel,
								{t: response.t})),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						A2(
							$author$project$ChangePasswordPage$updateChangePasswordModel,
							model,
							_Utils_update(
								newChangePasswordModel,
								{t: 'Ocorreu um erro ao alterar a senha'})),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Loadable$Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$Loadable$Got = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$HConfirming = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$HExporting = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$HGotComplaint = function (a) {
	return {$: 7, a: a};
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Helpers$competenceIsBefore = F2(
	function (comp1, comp2) {
		return (_Utils_cmp(comp1.ci, comp2.ci) < 0) || (_Utils_eq(comp1.ci, comp2.ci) && (_Utils_cmp(comp1.cc, comp2.cc) < 0));
	});
var $author$project$Types$Complaint = F4(
	function (id, competence, data, protocol) {
		return {aw: competence, bv: data, aK: id, bX: protocol};
	});
var $author$project$Types$Competence = F2(
	function (year, trimester) {
		return {cc: trimester, ci: year};
	});
var $author$project$Api$competenceDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Types$Competence,
	A2($elm$json$Json$Decode$field, 'year', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'trimester', $elm$json$Json$Decode$int));
var $author$project$Types$ComplaintData = function (agreementNumber) {
	return function (complainer) {
		return function (complaintChannel) {
			return function (date) {
				return function (document) {
					return function (id) {
						return function (logDescription) {
							return function (logs) {
								return function (logTitle) {
									return function (processNumber) {
										return function (protocol) {
											return function (reason) {
												return function (salesChannel) {
													return function (subject) {
														return function (status) {
															return function (isAccordionOpen) {
																return function (complaintId) {
																	return function (createdAt) {
																		return function (register) {
																			return {bi: agreementNumber, br: complainer, bs: complaintChannel, bt: complaintId, H: createdAt, ay: date, aA: document, aK: id, X: isAccordionOpen, bL: logDescription, bM: logTitle, ak: logs, bW: processNumber, bX: protocol, bY: reason, b_: register, b0: salesChannel, b3: status, b6: subject};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$Complainer = F6(
	function (birthDate, document, gender, type_, zipCode, name) {
		return {bm: birthDate, aA: document, bD: gender, bQ: name, cd: type_, cj: zipCode};
	});
var $author$project$Api$dateStringDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$left(10),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split('-'),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$reverse,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$join('/'),
					$elm$json$Json$Decode$succeed)))),
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map6 = _Json_map6;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $author$project$Helpers$maskStringHelper = F3(
	function (pattern, value, result) {
		maskStringHelper:
		while (true) {
			var valueUncons = $elm$core$String$uncons(value);
			if (!valueUncons.$) {
				var _v1 = valueUncons.a;
				var charValue = _v1.a;
				var rest = _v1.b;
				var _v2 = $elm$core$String$uncons(pattern);
				if (!_v2.$) {
					var _v3 = _v2.a;
					var a = _v3.a;
					var b = _v3.b;
					if (a === '#') {
						var $temp$pattern = b,
							$temp$value = rest,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(charValue));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					} else {
						var $temp$pattern = b,
							$temp$value = value,
							$temp$result = _Utils_ap(
							result,
							$elm$core$String$fromChar(a));
						pattern = $temp$pattern;
						value = $temp$value;
						result = $temp$result;
						continue maskStringHelper;
					}
				} else {
					return result;
				}
			} else {
				return result;
			}
		}
	});
var $elm$core$String$filter = _String_filter;
var $author$project$Helpers$onlyNumbersString = $elm$core$String$filter($elm$core$Char$isDigit);
var $author$project$Helpers$maskStringNumber = F2(
	function (pattern, value) {
		return function (v) {
			return A3($author$project$Helpers$maskStringHelper, pattern, v, '');
		}(
			$author$project$Helpers$onlyNumbersString(value));
	});
var $author$project$Helpers$maskDocument = function (document) {
	return (A2($elm$core$Basics$composeR, $author$project$Helpers$onlyNumbersString, $elm$core$String$length)(document) > 11) ? A2($author$project$Helpers$maskStringNumber, '##.###.###/####-##', document) : A2($author$project$Helpers$maskStringNumber, '###.###.###-##', document);
};
var $author$project$Helpers$maskZipCode = function (zipCode) {
	return A2($author$project$Helpers$maskStringNumber, '#####-###', zipCode);
};
var $author$project$Api$nullableDecoder = F2(
	function (decoder, _default) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(_default),
			$elm$json$Json$Decode$nullable(decoder));
	});
var $author$project$Api$complainerDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Types$Complainer,
	A2(
		$elm$json$Json$Decode$field,
		'complainer_birthdate',
		A2($author$project$Api$nullableDecoder, $author$project$Api$dateStringDecoder, '')),
	A2(
		$elm$json$Json$Decode$andThen,
		A2($elm$core$Basics$composeR, $author$project$Helpers$maskDocument, $elm$json$Json$Decode$succeed),
		A2($elm$json$Json$Decode$field, 'complainer_document', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'complainer_gender', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'complainer_type', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$andThen,
		A2($elm$core$Basics$composeR, $author$project$Helpers$maskZipCode, $elm$json$Json$Decode$succeed),
		A2($elm$json$Json$Decode$field, 'complainer_zip_code', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'complainer_name', $elm$json$Json$Decode$string));
var $author$project$Types$ComplaintDataLog = F4(
	function (description, createdAt, id, title) {
		return {H: createdAt, ab: description, aK: id, ba: title};
	});
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {av: col, bu: contextStack, aU: problem, a2: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.a2, s.av, x, s.e));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{av: col, e: s0.e, f: s0.f, b: offset, a2: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.a2, s.av, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{av: 1, e: s.e, f: s.f, b: s.b + 1, a2: s.a2 + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{av: s.av + 1, e: s.e, f: s.f, b: newOffset, a2: s.a2, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.a2, s.av, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{av: newCol, e: s.e, f: s.f, b: newOffset, a2: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {av: col, aU: problem, a2: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.a2, p.av, p.aU);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{av: 1, e: _List_Nil, f: 1, b: 0, a2: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Api$complaintDataLogDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'created_at',
			$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'description',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Types$ComplaintDataLog)))));
var $author$project$Types$ComplaintDate = F2(
	function (opening, closing) {
		return {bq: closing, bV: opening};
	});
var $author$project$Api$complaintDateDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Types$ComplaintDate,
	A2($elm$json$Json$Decode$field, 'opening_date', $author$project$Api$dateStringDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'closing_date',
		A2($author$project$Api$nullableDecoder, $author$project$Api$dateStringDecoder, '')));
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Api$complaintDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'register',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'created_at',
		$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'complaint_id',
			$elm$json$Json$Decode$string,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				false,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'status',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'subject',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'sales_channel',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'reason',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'protocol',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'process_number',
										$elm$json$Json$Decode$string,
										A2(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
											'',
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'logs',
												$elm$json$Json$Decode$list($author$project$Api$complaintDataLogDecoder),
												_List_Nil,
												A2(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
													'',
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'id',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'document',
															A2(
																$elm$json$Json$Decode$andThen,
																A2($elm$core$Basics$composeR, $author$project$Helpers$maskDocument, $elm$json$Json$Decode$succeed),
																$elm$json$Json$Decode$string),
															A2(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
																$author$project$Api$complaintDateDecoder,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'complaint_channel',
																	$elm$json$Json$Decode$int,
																	A2(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
																		$author$project$Api$complainerDecoder,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'agreement_number',
																			$elm$json$Json$Decode$string,
																			$elm$json$Json$Decode$succeed($author$project$Types$ComplaintData))))))))))))))))))));
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Api$complaintDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Types$Complaint,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	$author$project$Api$competenceDecoder,
	A2(
		$elm$json$Json$Decode$map,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$indexedMap($elm$core$Tuple$pair),
			$elm$core$Dict$fromList),
		A2(
			$elm$json$Json$Decode$field,
			'data',
			A2(
				$author$project$Api$nullableDecoder,
				$elm$json$Json$Decode$list($author$project$Api$complaintDataDecoder),
				_List_Nil))),
	A2($elm$json$Json$Decode$field, 'protocol', $elm$json$Json$Decode$string));
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Helpers$formatDate = A2($elm$core$String$replace, '/', '-');
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Helpers$nullIfEmptyEncoder = function (string) {
	if (string === '') {
		return $elm$json$Json$Encode$null;
	} else {
		var actualString = string;
		return $elm$json$Json$Encode$string(actualString);
	}
};
var $author$project$Helpers$complainerEncoder = function (complainer) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nome',
				$elm$json$Json$Encode$string(complainer.bQ)),
				_Utils_Tuple2(
				'identificacaoReclamante',
				$elm$json$Json$Encode$string(
					$author$project$Helpers$onlyNumbersString(complainer.aA))),
				_Utils_Tuple2(
				'cep',
				$author$project$Helpers$nullIfEmptyEncoder(
					$author$project$Helpers$onlyNumbersString(complainer.cj))),
				_Utils_Tuple2(
				'sexo',
				$author$project$Helpers$nullIfEmptyEncoder(complainer.bD)),
				_Utils_Tuple2(
				'tipo',
				$elm$json$Json$Encode$int(complainer.cd)),
				_Utils_Tuple2(
				'dataNascimento',
				$author$project$Helpers$nullIfEmptyEncoder(
					$author$project$Helpers$formatDate(complainer.bm)))
			]));
};
var $author$project$Helpers$complaintEncoder = function (complaint) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'registro',
				$elm$json$Json$Encode$int(complaint.b_)),
				_Utils_Tuple2(
				'identificacaoResponsavel',
				$elm$json$Json$Encode$string(
					$author$project$Helpers$onlyNumbersString(complaint.aA))),
				_Utils_Tuple2(
				'dataAbertura',
				$elm$json$Json$Encode$string(
					$author$project$Helpers$formatDate(complaint.ay.bV))),
				_Utils_Tuple2(
				'dataEncerramento',
				$author$project$Helpers$nullIfEmptyEncoder(
					$author$project$Helpers$formatDate(complaint.ay.bq))),
				_Utils_Tuple2(
				'numeroProcessoSusep',
				$author$project$Helpers$nullIfEmptyEncoder(complaint.bW)),
				_Utils_Tuple2(
				'numeroContrato',
				$author$project$Helpers$nullIfEmptyEncoder(complaint.bi)),
				_Utils_Tuple2(
				'protocolo',
				$elm$json$Json$Encode$string(complaint.bX)),
				_Utils_Tuple2(
				'motivo',
				$elm$json$Json$Encode$int(complaint.bY)),
				_Utils_Tuple2(
				'canalVenda',
				$elm$json$Json$Encode$int(complaint.b0)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(complaint.b3)),
				_Utils_Tuple2(
				'canalReclamacao',
				$elm$json$Json$Encode$int(complaint.bs)),
				_Utils_Tuple2(
				'assunto',
				$elm$json$Json$Encode$int(complaint.b6)),
				_Utils_Tuple2(
				'reclamante',
				$author$project$Helpers$complainerEncoder(complaint.br))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Helpers$complaintDataEncoder = F2(
	function (complaint, fipCode) {
		var newData = $elm$core$Dict$values(complaint.bv);
		var competenceString = _Utils_ap(
			$elm$core$String$fromInt(complaint.aw.ci),
			A3(
				$elm$core$String$padLeft,
				2,
				'0',
				$elm$core$String$fromInt(complaint.aw.cc)));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'entidade',
					$elm$json$Json$Encode$string(
						A2($elm$core$String$filter, $elm$core$Char$isDigit, fipCode))),
					_Utils_Tuple2(
					'competencia',
					$elm$json$Json$Encode$string(competenceString)),
					_Utils_Tuple2(
					'reclamacoes',
					A2($elm$json$Json$Encode$list, $author$project$Helpers$complaintEncoder, newData))
				]));
	});
var $author$project$Helpers$complaintToJson = F2(
	function (complaint, fipCode) {
		return A2(
			$elm$json$Json$Encode$encode,
			4,
			A2($author$project$Helpers$complaintDataEncoder, complaint, fipCode));
	});
var $author$project$Types$HGotConfirmed = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$put = F4(
	function (apiUrl, token, body, expect) {
		return A5($author$project$Api$request, 'PUT', token, apiUrl, body, expect);
	});
var $author$project$Api$putConfirmSent = F4(
	function (apiUrl, token, complaintId, protocol) {
		return A4(
			$author$project$Api$put,
			apiUrl + ('/complaint/' + (complaintId + '/confirm')),
			token,
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'protocol',
							$elm$json$Json$Encode$string(protocol))
						]))),
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HGotConfirmed),
				$author$project$Api$responseDecoder));
	});
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $author$project$HomePage$update = F3(
	function (msg, model, token) {
		var homeModel = model.k;
		var competence = homeModel.aw;
		switch (msg.$) {
			case 0:
				var responseResult = msg.a;
				var errorModel = _Utils_update(
					model,
					{
						k: _Utils_update(
							homeModel,
							{
								A: $author$project$Loadable$Error('Ocorreu um erro ao buscar as reclamações')
							})
					});
				if (!responseResult.$) {
					var response = responseResult.a;
					if (response.b3) {
						var _v2 = A2(
							$elm$json$Json$Decode$decodeValue,
							$elm$json$Json$Decode$list($author$project$Api$complaintDecoder),
							response.bv);
						if (!_v2.$) {
							var complaints = _v2.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										k: _Utils_update(
											homeModel,
											{
												A: $author$project$Loadable$Got(complaints)
											})
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(errorModel, $elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(errorModel, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(errorModel, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{
									_: $author$project$Types$HExporting(id)
								})
						}),
					A4(
						$author$project$Api$getComplaint,
						model.bk,
						token.ca,
						id,
						A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HGotComplaint)));
			case 2:
				var complaint = msg.a;
				var loadingTuple = _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{A: $author$project$Loadable$Loading})
						}),
					A2($author$project$Api$getComplaints, model.bk, token.ca));
				var _v3 = homeModel.A;
				switch (_v3.$) {
					case 0:
						return loadingTuple;
					case 2:
						return loadingTuple;
					default:
						var complaints = _v3.a;
						var _v4 = A2(
							$elm$core$List$any,
							function (c) {
								return A2($author$project$Helpers$competenceIsBefore, c.aw, complaint.aw) && $elm$core$String$isEmpty(c.bX);
							},
							complaints) ? _Utils_Tuple2(
							$author$project$Types$HNone,
							$author$project$Helpers$errorAlert(
								A2($author$project$Types$Alert, 'Trimestres abertos', 'Para confirmar o envio deste trimestre, primeiro confirme os anteriores'))) : _Utils_Tuple2(
							$author$project$Types$HConfirming(complaint.aK),
							$elm$core$Platform$Cmd$none);
						var submitting = _v4.a;
						var cmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									k: _Utils_update(
										homeModel,
										{_: submitting})
								}),
							cmd);
				}
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{_: $author$project$Types$HNone})
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var protocol = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{
									Q: A2($elm$core$String$filter, $elm$core$Char$isDigit, protocol)
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var _v5 = function () {
					var _v6 = homeModel._;
					if (_v6.$ === 2) {
						var id = _v6.a;
						return _Utils_Tuple3(
							A4($author$project$Api$putConfirmSent, model.bk, token.ca, id, homeModel.Q),
							homeModel._,
							$elm$core$Maybe$Just(true));
					} else {
						return _Utils_Tuple3(
							$author$project$Helpers$errorAlert(
								A2($author$project$Types$Alert, 'Error', 'Ocorreu um erro ao tentar confirmar')),
							$author$project$Types$HNone,
							$elm$core$Maybe$Just(false));
					}
				}();
				var cmd = _v5.a;
				var newSubmitting = _v5.b;
				var newSubmittingProtocol = _v5.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{_: newSubmitting, P: newSubmittingProtocol})
						}),
					cmd);
			case 6:
				var responseResult = msg.a;
				var errorModel = _Utils_update(
					model,
					{
						k: _Utils_update(
							homeModel,
							{
								P: $elm$core$Maybe$Just(false)
							})
					});
				if (!responseResult.$) {
					var response = responseResult.a;
					return response.b3 ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								k: _Utils_update(
									homeModel,
									{A: $author$project$Loadable$Loading, _: $author$project$Types$HNone, P: $elm$core$Maybe$Nothing, Q: ''})
							}),
						A2($author$project$Api$getComplaints, model.bk, token.ca)) : _Utils_Tuple2(errorModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(errorModel, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var responseResult = msg.a;
				var newHomeModel = _Utils_update(
					homeModel,
					{_: $author$project$Types$HNone});
				var newModel = _Utils_update(
					model,
					{k: newHomeModel});
				var errorCmd = $author$project$Helpers$errorAlert(
					A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao carregar a reclamação'));
				if (!responseResult.$) {
					var response = responseResult.a;
					if (response.b3) {
						var _v9 = A2($elm$json$Json$Decode$decodeValue, $author$project$Api$complaintDecoder, response.bv);
						if (!_v9.$) {
							var newComplaint = _v9.a;
							return _Utils_Tuple2(
								newModel,
								A3(
									$elm$file$File$Download$string,
									'dados.json',
									'application/json',
									A2($author$project$Helpers$complaintToJson, newComplaint, model.bC)));
						} else {
							return _Utils_Tuple2(newModel, errorCmd);
						}
					} else {
						return _Utils_Tuple2(newModel, errorCmd);
					}
				} else {
					return _Utils_Tuple2(newModel, errorCmd);
				}
			case 8:
				var year = msg.a;
				var formattedYear = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(
						A2($author$project$Helpers$maskStringNumber, '####', year)));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{
									aw: _Utils_update(
										competence,
										{ci: formattedYear})
								})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var trimester = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k: _Utils_update(
								homeModel,
								{
									aw: _Utils_update(
										competence,
										{
											cc: A2(
												$elm$core$Maybe$withDefault,
												0,
												$elm$core$String$toInt(trimester))
										})
								})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Helpers$reverseDate = F3(
	function (separator, joiner, date) {
		return ($elm$core$String$length(date) < 10) ? date : A2(
			$elm$core$String$join,
			joiner,
			$elm$core$List$reverse(
				A2($elm$core$String$split, separator, date)));
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Helpers$dateIsBigger = F2(
	function (date1, date2) {
		var dateToPosix = A2(
			$elm$core$Basics$composeR,
			A2($author$project$Helpers$reverseDate, '/', '-'),
			A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime, $elm$core$Result$toMaybe));
		var date2Posix = dateToPosix(date2);
		var date1Posix = dateToPosix(date1);
		return A3(
			$elm$core$Maybe$map2,
			F2(
				function (d1, d2) {
					return _Utils_cmp(
						$elm$time$Time$posixToMillis(d1),
						$elm$time$Time$posixToMillis(d2)) > 0;
				}),
			date1Posix,
			date2Posix);
	});
var $author$project$InsertEditPage$emptyComplainer = {bm: '', aA: '', bD: '', bQ: '', cd: -1, cj: ''};
var $author$project$InsertEditPage$emptyComplaint = {
	bi: '',
	br: $author$project$InsertEditPage$emptyComplainer,
	bs: -1,
	bt: '',
	H: $elm$time$Time$millisToPosix(0),
	ay: {bq: '', bV: ''},
	aA: '',
	aK: '',
	X: true,
	bL: '',
	bM: '',
	ak: _List_Nil,
	bW: '',
	bX: '',
	bY: -1,
	b_: 0,
	b0: -1,
	b3: -1,
	b6: -1
};
var $author$project$Helpers$getIsoDate = function (dateString) {
	return ($elm$core$String$length(dateString) < 10) ? $elm$core$Maybe$Nothing : $elm$core$Result$toMaybe(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
			A2(
				$elm$core$String$join,
				'-',
				$elm$core$List$reverse(
					A2($elm$core$String$split, '/', dateString)))));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Helpers$maskDate = $author$project$Helpers$maskStringNumber('##/##/####');
var $author$project$Helpers$getValidatedDate = function (dateStr) {
	var maskedDate = $author$project$Helpers$maskDate(dateStr);
	return ($elm$core$String$length(dateStr) < 10) ? maskedDate : A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always(maskedDate),
			$author$project$Helpers$getIsoDate(maskedDate)));
};
var $author$project$Types$IEGotCreated = function (a) {
	return {$: 26, a: a};
};
var $author$project$Api$onlyDigits = $elm$core$String$filter($elm$core$Char$isDigit);
var $author$project$Api$complainerEncoder = function (complainer) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(complainer.bQ)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(complainer.aA)),
				_Utils_Tuple2(
				'zip_code',
				$elm$json$Json$Encode$string(
					$author$project$Api$onlyDigits(complainer.cj))),
				_Utils_Tuple2(
				'gender',
				$elm$json$Json$Encode$string(complainer.bD)),
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$int(complainer.cd)),
				_Utils_Tuple2(
				'birthDate',
				$elm$json$Json$Encode$string(
					A3($author$project$Helpers$reverseDate, '/', '-', complainer.bm)))
			]));
};
var $author$project$Api$complaintDataEncoder = function (complaintData) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(complaintData.aK)),
				_Utils_Tuple2(
				'complaint_id',
				$elm$json$Json$Encode$string(complaintData.bt)),
				_Utils_Tuple2(
				'document',
				$elm$json$Json$Encode$string(
					$author$project$Api$onlyDigits(complaintData.aA))),
				_Utils_Tuple2(
				'opening_date',
				$elm$json$Json$Encode$string(
					A3($author$project$Helpers$reverseDate, '/', '-', complaintData.ay.bV))),
				_Utils_Tuple2(
				'closing_date',
				$elm$json$Json$Encode$string(
					A3($author$project$Helpers$reverseDate, '/', '-', complaintData.ay.bq))),
				_Utils_Tuple2(
				'agreement_number',
				$elm$json$Json$Encode$string(complaintData.bi)),
				_Utils_Tuple2(
				'process_number',
				$elm$json$Json$Encode$string(complaintData.bW)),
				_Utils_Tuple2(
				'protocol',
				$elm$json$Json$Encode$string(complaintData.bX)),
				_Utils_Tuple2(
				'reason',
				$elm$json$Json$Encode$int(complaintData.bY)),
				_Utils_Tuple2(
				'sales_channel',
				$elm$json$Json$Encode$int(complaintData.b0)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(complaintData.b3)),
				_Utils_Tuple2(
				'complaint_channel',
				$elm$json$Json$Encode$int(complaintData.bs)),
				_Utils_Tuple2(
				'subject',
				$elm$json$Json$Encode$int(complaintData.b6)),
				_Utils_Tuple2(
				'complainer',
				$author$project$Api$complainerEncoder(complaintData.br)),
				_Utils_Tuple2(
				'register',
				$elm$json$Json$Encode$int(complaintData.b_)),
				_Utils_Tuple2(
				'log_description',
				$elm$json$Json$Encode$string(complaintData.bL)),
				_Utils_Tuple2(
				'log_title',
				$elm$json$Json$Encode$string(complaintData.bM))
			]));
};
var $author$project$Api$complaintEncoder = function (complaint) {
	var data = complaint.bv;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'trimester',
				$elm$json$Json$Encode$int(complaint.aw.cc)),
				_Utils_Tuple2(
				'year',
				$elm$json$Json$Encode$int(complaint.aw.ci)),
				_Utils_Tuple2(
				'data',
				A2(
					$elm$json$Json$Encode$list,
					$author$project$Api$complaintDataEncoder,
					$elm$core$Dict$values(data)))
			]));
};
var $author$project$Api$postCreateComplaint = F3(
	function (apiUrl, token, complaint) {
		return A4(
			$author$project$Api$post,
			apiUrl + '/complaint',
			token,
			$elm$http$Http$jsonBody(
				$author$project$Api$complaintEncoder(complaint)),
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEGotCreated),
				$author$project$Api$responseDecoder));
	});
var $author$project$Types$IEGotUpdated = function (a) {
	return {$: 28, a: a};
};
var $author$project$Api$putUpdateComplaint = F3(
	function (apiUrl, token, complaint) {
		return A4(
			$author$project$Api$put,
			apiUrl + ('/complaint/' + complaint.aK),
			token,
			$elm$http$Http$jsonBody(
				$author$project$Api$complaintEncoder(complaint)),
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEGotUpdated),
				$author$project$Api$responseDecoder));
	});
var $author$project$Helpers$successAlert = _Platform_outgoingPort(
	'successAlert',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'message',
					$elm$json$Json$Encode$string($.t)),
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string($.ba))
				]));
	});
var $author$project$InsertEditPage$update = F3(
	function (msg, model, token) {
		var homeModel = model.k;
		var insertEditModel = model.c;
		var complaint = insertEditModel.d;
		var competence = complaint.aw;
		switch (msg.$) {
			case 0:
				var year = msg.a;
				var newYear = $elm$core$String$isEmpty(year) ? 0 : A2(
					$elm$core$Maybe$withDefault,
					competence.ci,
					$elm$core$String$toInt(
						A2($elm$core$String$left, 4, year)));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{
											aw: _Utils_update(
												competence,
												{cc: 0, ci: newYear})
										})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var trimester = msg.a;
				var newTrimester = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(trimester));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{
											aw: _Utils_update(
												competence,
												{cc: newTrimester})
										})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var newData = A3($elm$core$Dict$insert, insertEditModel.V, $author$project$InsertEditPage$emptyComplaint, complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData}),
									V: insertEditModel.V + 1
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var complaintKey = msg.a;
				var newData = A2($elm$core$Dict$remove, complaintKey, complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var complaintKey = msg.a;
				var logDescription = msg.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bL: logDescription});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var complaintKey = msg.a;
				var logTitle = msg.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bM: logTitle});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var log = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									Z: $elm$core$Maybe$Just(log)
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{Z: $elm$core$Maybe$Nothing})
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var complaintKey = msg.a;
				var document = msg.b;
				var newDocument = $author$project$Helpers$maskDocument(document);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{aA: newDocument});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var complaintKey = msg.a;
				var openingDate = msg.b;
				var newOpeningDate = $author$project$Helpers$getValidatedDate(openingDate);
				var date = A2(
					$elm$core$Maybe$withDefault,
					{bq: '', bV: ''},
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ay;
						},
						A2($elm$core$Dict$get, complaintKey, complaint.bv)));
				var _v1 = function () {
					var _v2 = A2($author$project$Helpers$dateIsBigger, newOpeningDate, date.bq);
					if (!_v2.$) {
						var isBigger = _v2.a;
						return isBigger ? _Utils_Tuple2(
							date.bV,
							$author$project$Helpers$errorAlert(
								A2($author$project$Types$Alert, 'Data inválida', 'A data de abertura não pode ser maior que a data de fechamento'))) : _Utils_Tuple2(newOpeningDate, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(newOpeningDate, $elm$core$Platform$Cmd$none);
					}
				}();
				var validatedOpeningDate = _v1.a;
				var cmd = _v1.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{
									ay: _Utils_update(
										date,
										{bV: validatedOpeningDate})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					cmd);
			case 10:
				var complaintKey = msg.a;
				var closingDate = msg.b;
				var newClosingDate = $author$project$Helpers$getValidatedDate(closingDate);
				var date = A2(
					$elm$core$Maybe$withDefault,
					{bq: '', bV: ''},
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ay;
						},
						A2($elm$core$Dict$get, complaintKey, complaint.bv)));
				var _v3 = function () {
					var _v4 = A2($author$project$Helpers$dateIsBigger, date.bV, newClosingDate);
					if (!_v4.$) {
						var isBigger = _v4.a;
						return isBigger ? _Utils_Tuple2(
							date.bq,
							$author$project$Helpers$errorAlert(
								A2($author$project$Types$Alert, 'Data inválida', 'A data de fechamento não pode ser menor do que a data de abertura'))) : _Utils_Tuple2(newClosingDate, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(newClosingDate, $elm$core$Platform$Cmd$none);
					}
				}();
				var validatedClosingDate = _v3.a;
				var cmd = _v3.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{
									ay: _Utils_update(
										date,
										{bq: validatedClosingDate})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					cmd);
			case 11:
				var complaintKey = msg.a;
				var agreementNumber = msg.b;
				var newAgreementNumber = A2($elm$core$String$filter, $elm$core$Char$isDigit, agreementNumber);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bi: newAgreementNumber});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var complaintKey = msg.a;
				var processNumber = msg.b;
				var newProcessNumber = A2($elm$core$String$filter, $elm$core$Char$isDigit, processNumber);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bW: newProcessNumber});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var complaintKey = msg.a;
				var protocol = msg.b;
				var newProtocol = A2($elm$core$String$filter, $elm$core$Char$isDigit, protocol);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bX: newProtocol});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var complaintKey = msg.a;
				var reason = msg.b;
				var newReason = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(reason));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bY: newReason});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var complaintKey = msg.a;
				var salesChannel = msg.b;
				var newSalesChannel = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(salesChannel));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{b0: newSalesChannel});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var complaintKey = msg.a;
				var status = msg.b;
				var newStatus = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(status));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var date = complaintData.ay;
							var newClosingDate = (newStatus === 4) ? '' : date.bq;
							return _Utils_update(
								complaintData,
								{
									ay: _Utils_update(
										date,
										{bq: newClosingDate}),
									b3: newStatus
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var complaintKey = msg.a;
				var channel = msg.b;
				var newChannel = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(channel));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{bs: newChannel});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var complaintKey = msg.a;
				var subject = msg.b;
				var newSubject = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(subject));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{b6: newSubject});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var complaintKey = msg.a;
				var name = msg.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{bQ: name})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var complaintKey = msg.a;
				var document = msg.b;
				var newDocument = ($elm$core$String$length(document) > 14) ? A2($author$project$Helpers$maskStringNumber, '##.###.###/####-##', document) : A2($author$project$Helpers$maskStringNumber, '###.###.###-##', document);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							var _v5 = (($elm$core$String$length(document) > 14) && ($elm$core$String$length(complainer.aA) <= 14)) ? _Utils_Tuple3('', '', '') : _Utils_Tuple3(complainer.bD, complainer.bm, complainer.cj);
							var newGender = _v5.a;
							var newBirthDate = _v5.b;
							var newZipCode = _v5.c;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{bm: newBirthDate, aA: newDocument, bD: newGender, cj: newZipCode})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 21:
				var complaintKey = msg.a;
				var zipCode = msg.b;
				var newZipCode = $author$project$Helpers$maskZipCode(zipCode);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{cj: newZipCode})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var complaintKey = msg.a;
				var gender = msg.b;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{bD: gender})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var complaintKey = msg.a;
				var type_ = msg.b;
				var newType = A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(type_));
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{cd: newType})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var complaintKey = msg.a;
				var birthDate = msg.b;
				var newBirthDate = $author$project$Helpers$getValidatedDate(birthDate);
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							var complainer = complaintData.br;
							return _Utils_update(
								complaintData,
								{
									br: _Utils_update(
										complainer,
										{bm: newBirthDate})
								});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var complaintKey = msg.a;
				var newData = A3(
					$elm$core$Dict$update,
					complaintKey,
					$elm$core$Maybe$map(
						function (complaintData) {
							return _Utils_update(
								complaintData,
								{X: !complaintData.X});
						}),
					complaint.bv);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{
									d: _Utils_update(
										complaint,
										{bv: newData})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 30:
				var cmd = $elm$core$String$isEmpty(insertEditModel.d.aK) ? $author$project$Api$postCreateComplaint : $author$project$Api$putUpdateComplaint;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								insertEditModel,
								{_: true})
						}),
					A3(cmd, model.bk, token.ca, complaint));
			case 27:
				var responseResult = msg.a;
				var newInsertEditModel = _Utils_update(
					insertEditModel,
					{Y: false});
				var newModel = _Utils_update(
					model,
					{c: newInsertEditModel});
				var errorCmd = $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							$author$project$Helpers$errorAlert(
							A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao carregar a reclamação')),
							A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Home)
						]));
				if (!responseResult.$) {
					var response = responseResult.a;
					if (response.b3) {
						var _v7 = A2($elm$json$Json$Decode$decodeValue, $author$project$Api$complaintDecoder, response.bv);
						if (!_v7.$) {
							var newComplaint = _v7.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										c: _Utils_update(
											newInsertEditModel,
											{d: newComplaint})
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(newModel, errorCmd);
						}
					} else {
						return _Utils_Tuple2(newModel, errorCmd);
					}
				} else {
					return _Utils_Tuple2(newModel, errorCmd);
				}
			case 26:
				var responseResult = msg.a;
				var newInsertEditModel = _Utils_update(
					insertEditModel,
					{_: false});
				var newModel = _Utils_update(
					model,
					{c: newInsertEditModel});
				if (!responseResult.$) {
					var response = responseResult.a;
					if (response.b3) {
						var _v9 = A2($elm$json$Json$Decode$decodeValue, $author$project$Api$complaintDecoder, response.bv);
						if (!_v9.$) {
							var newComplaint = _v9.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										c: _Utils_update(
											newInsertEditModel,
											{d: newComplaint})
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$author$project$Route$pushUrl,
											model.aO,
											$author$project$Route$Edit(newComplaint.aK)),
											$author$project$Helpers$successAlert(
											A2($author$project$Types$Alert, 'Sucesso', 'Reclamação salva com sucesso!'))
										])));
						} else {
							return _Utils_Tuple2(
								newModel,
								$author$project$Helpers$errorAlert(
									A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao salvar a reclamação')));
						}
					} else {
						return _Utils_Tuple2(
							newModel,
							$author$project$Helpers$errorAlert(
								A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao salvar a reclamação')));
					}
				} else {
					return _Utils_Tuple2(
						newModel,
						$author$project$Helpers$errorAlert(
							A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao salvar a reclamação')));
				}
			case 28:
				var responseResult = msg.a;
				var newInsertEditModel = _Utils_update(
					insertEditModel,
					{_: false});
				var newModel = _Utils_update(
					model,
					{c: newInsertEditModel});
				if (!responseResult.$) {
					var response = responseResult.a;
					return response.b3 ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									insertEditModel,
									{Y: true, _: false})
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Helpers$successAlert(
									A2($author$project$Types$Alert, 'Sucesso', 'Reclamação atualizada com sucesso')),
									A4(
									$author$project$Api$getComplaint,
									model.bk,
									token.ca,
									insertEditModel.d.aK,
									A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEGotComplaint))
								]))) : _Utils_Tuple2(
						newModel,
						$author$project$Helpers$errorAlert(
							A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao atualizar a reclamação')));
				} else {
					return _Utils_Tuple2(
						newModel,
						$author$project$Helpers$errorAlert(
							A2($author$project$Types$Alert, 'Erro', 'Ocorreu um erro ao atualizar a reclamação')));
				}
			default:
				var jsonResult = A2($author$project$Helpers$complaintToJson, complaint, model.bC);
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$string, 'dados.json', 'application/json', jsonResult));
		}
	});
var $author$project$Login$Login$GoToForgotPassword = {$: 3};
var $author$project$Login$Login$GoToSignUp = {$: 4};
var $author$project$Login$Login$LoggedOut = {$: 2};
var $author$project$Login$Login$NoOp = {$: 0};
var $author$project$Login$Login$SignedIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Login$Login$facebookLogin = _Platform_outgoingPort(
	'facebookLogin',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $truqu$elm_base64$Base64$Decode$pad = function (input) {
	var _v0 = $elm$core$String$length(input) % 4;
	switch (_v0) {
		case 3:
			return input + '=';
		case 2:
			return input + '==';
		default:
			return input;
	}
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $truqu$elm_base64$Base64$Decode$charToInt = function (_char) {
	switch (_char) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
		case 'E':
			return 4;
		case 'F':
			return 5;
		case 'G':
			return 6;
		case 'H':
			return 7;
		case 'I':
			return 8;
		case 'J':
			return 9;
		case 'K':
			return 10;
		case 'L':
			return 11;
		case 'M':
			return 12;
		case 'N':
			return 13;
		case 'O':
			return 14;
		case 'P':
			return 15;
		case 'Q':
			return 16;
		case 'R':
			return 17;
		case 'S':
			return 18;
		case 'T':
			return 19;
		case 'U':
			return 20;
		case 'V':
			return 21;
		case 'W':
			return 22;
		case 'X':
			return 23;
		case 'Y':
			return 24;
		case 'Z':
			return 25;
		case 'a':
			return 26;
		case 'b':
			return 27;
		case 'c':
			return 28;
		case 'd':
			return 29;
		case 'e':
			return 30;
		case 'f':
			return 31;
		case 'g':
			return 32;
		case 'h':
			return 33;
		case 'i':
			return 34;
		case 'j':
			return 35;
		case 'k':
			return 36;
		case 'l':
			return 37;
		case 'm':
			return 38;
		case 'n':
			return 39;
		case 'o':
			return 40;
		case 'p':
			return 41;
		case 'q':
			return 42;
		case 'r':
			return 43;
		case 's':
			return 44;
		case 't':
			return 45;
		case 'u':
			return 46;
		case 'v':
			return 47;
		case 'w':
			return 48;
		case 'x':
			return 49;
		case 'y':
			return 50;
		case 'z':
			return 51;
		case '0':
			return 52;
		case '1':
			return 53;
		case '2':
			return 54;
		case '3':
			return 55;
		case '4':
			return 56;
		case '5':
			return 57;
		case '6':
			return 58;
		case '7':
			return 59;
		case '8':
			return 60;
		case '9':
			return 61;
		case '+':
			return 62;
		case '/':
			return 63;
		default:
			return 0;
	}
};
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Char$fromCode = _Char_fromCode;
var $truqu$elm_base64$Base64$Decode$intToString = A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar);
var $truqu$elm_base64$Base64$Decode$add = F2(
	function (_char, _v0) {
		var curr = _v0.a;
		var need = _v0.b;
		var res = _v0.c;
		var shiftAndAdd = function (_int) {
			return (63 & _int) | (curr << 6);
		};
		return (!need) ? ((!(128 & _char)) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(_char))) : (((224 & _char) === 192) ? _Utils_Tuple3(31 & _char, 1, res) : (((240 & _char) === 224) ? _Utils_Tuple3(15 & _char, 2, res) : _Utils_Tuple3(7 & _char, 3, res)))) : ((need === 1) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(
					shiftAndAdd(_char)))) : _Utils_Tuple3(
			shiftAndAdd(_char),
			need - 1,
			res));
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $truqu$elm_base64$Base64$Decode$toUTF16 = F2(
	function (_char, acc) {
		return _Utils_Tuple3(
			0,
			0,
			A2(
				$truqu$elm_base64$Base64$Decode$add,
				255 & (_char >>> 0),
				A2(
					$truqu$elm_base64$Base64$Decode$add,
					255 & (_char >>> 8),
					A2($truqu$elm_base64$Base64$Decode$add, 255 & (_char >>> 16), acc))));
	});
var $truqu$elm_base64$Base64$Decode$chomp = F2(
	function (char_, _v0) {
		var curr = _v0.a;
		var cnt = _v0.b;
		var utf8ToUtf16 = _v0.c;
		var _char = $truqu$elm_base64$Base64$Decode$charToInt(char_);
		if (cnt === 3) {
			return A2($truqu$elm_base64$Base64$Decode$toUTF16, curr | _char, utf8ToUtf16);
		} else {
			return _Utils_Tuple3((_char << ((3 - cnt) * 6)) | curr, cnt + 1, utf8ToUtf16);
		}
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Decode$initial = _Utils_Tuple3(
	0,
	0,
	_Utils_Tuple3(0, 0, ''));
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$endsWith = _String_endsWith;
var $truqu$elm_base64$Base64$Decode$stripNulls = F2(
	function (input, output) {
		return A2($elm$core$String$endsWith, '==', input) ? A2($elm$core$String$dropRight, 2, output) : (A2($elm$core$String$endsWith, '=', input) ? A2($elm$core$String$dropRight, 1, output) : output);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {bI: index, bN: match, bR: number, b7: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{bo: false, bP: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $truqu$elm_base64$Base64$Decode$validBase64Regex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z0-9\\/+]{4})*([A-Za-z0-9\\/+]{2}[A-Za-z0-9\\/+=]{2})?$'));
var $truqu$elm_base64$Base64$Decode$validate = function (input) {
	return A2($elm$regex$Regex$contains, $truqu$elm_base64$Base64$Decode$validBase64Regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err('Invalid base64');
};
var $truqu$elm_base64$Base64$Decode$wrapUp = function (_v0) {
	var _v1 = _v0.c;
	var need = _v1.b;
	var res = _v1.c;
	return (need > 0) ? $elm$core$Result$Err('Invalid UTF-16') : $elm$core$Result$Ok(res);
};
var $truqu$elm_base64$Base64$Decode$validateAndDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		$truqu$elm_base64$Base64$Decode$stripNulls(input),
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$core$String$foldl, $truqu$elm_base64$Base64$Decode$chomp, $truqu$elm_base64$Base64$Decode$initial),
				$truqu$elm_base64$Base64$Decode$wrapUp),
			$truqu$elm_base64$Base64$Decode$validate(input)));
};
var $truqu$elm_base64$Base64$Decode$decode = A2($elm$core$Basics$composeR, $truqu$elm_base64$Base64$Decode$pad, $truqu$elm_base64$Base64$Decode$validateAndDecode);
var $truqu$elm_base64$Base64$decode = $truqu$elm_base64$Base64$Decode$decode;
var $author$project$Login$Login$getTokenData = function (token) {
	return A2(
		$elm$core$Maybe$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$json$Json$Decode$decodeString(
				A2($elm$json$Json$Decode$field, 'token', $author$project$Login$Login$tokenDataDecoder)),
			$elm$core$Result$toMaybe),
		$elm$core$Result$toMaybe(
			$truqu$elm_base64$Base64$decode(token)));
};
var $author$project$Login$Login$googleLogin = _Platform_outgoingPort(
	'googleLogin',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Login$Login$GotLogin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Login$Login$loginEncoder = F2(
	function (user, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(user)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$Login$Login$post = F3(
	function (url, body, expect) {
		return $elm$http$Http$request(
			{bn: body, by: expect, bF: _List_Nil, bO: 'POST', b9: $elm$core$Maybe$Nothing, cb: $elm$core$Maybe$Nothing, cf: url});
	});
var $author$project$Login$Login$Response = F4(
	function (message, success, expire, token) {
		return {bz: expire, t: message, a8: success, ca: token};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Login$Login$responseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Login$Login$Response,
	A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(''),
			$elm$json$Json$Decode$succeed),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string))),
	A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(false),
			$elm$json$Json$Decode$succeed),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$bool))),
	A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(0),
			$elm$json$Json$Decode$succeed),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'expire', $elm$json$Json$Decode$int))),
	A2(
		$elm$json$Json$Decode$andThen,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(''),
			$elm$json$Json$Decode$succeed),
		$elm$json$Json$Decode$maybe(
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['data', 'token']),
				$elm$json$Json$Decode$string))));
var $author$project$Login$Login$postLogin = F3(
	function (apiUrl, user, password) {
		return A3(
			$author$project$Login$Login$post,
			apiUrl + '/auth/login',
			$elm$http$Http$jsonBody(
				A2($author$project$Login$Login$loginEncoder, user, password)),
			A2($elm$http$Http$expectJson, $author$project$Login$Login$GotLogin, $author$project$Login$Login$responseDecoder));
	});
var $author$project$Login$Login$postLoginEmail = F3(
	function (apiUrl, user, password) {
		return A3($author$project$Login$Login$postLogin, apiUrl, user, password);
	});
var $author$project$Login$Login$GotGoogleLogout = function (a) {
	return {$: 8, a: a};
};
var $author$project$Login$Login$postLogoutGoogle = F2(
	function (apiUrl, googleToken) {
		return A3(
			$author$project$Login$Login$post,
			apiUrl + '/auth/logout',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'provider',
							$elm$json$Json$Encode$string('google')),
							_Utils_Tuple2(
							'token',
							$elm$json$Json$Encode$string(googleToken))
						]))),
			A2($elm$http$Http$expectJson, $author$project$Login$Login$GotGoogleLogout, $author$project$Login$Login$responseDecoder));
	});
var $author$project$Login$Login$storeToken = _Platform_outgoingPort(
	'storeToken',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'data',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string($.p)),
									_Utils_Tuple2(
									'expiration',
									$elm$json$Json$Encode$int($.ac))
								]));
					}($.bv)),
					_Utils_Tuple2(
					'token',
					$elm$json$Json$Encode$string($.ca))
				]));
	});
var $author$project$Login$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{p: email}),
					$elm$core$Platform$Cmd$none,
					$author$project$Login$Login$NoOp);
			case 1:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{O: password}),
					$elm$core$Platform$Cmd$none,
					$author$project$Login$Login$NoOp);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: $elm$core$Maybe$Nothing, _: true}),
					A3($author$project$Login$Login$postLoginEmail, model.bk, model.p, model.O),
					$author$project$Login$Login$NoOp);
			case 3:
				var responseResult = msg.a;
				var newModel = _Utils_update(
					model,
					{_: false});
				if (!responseResult.$) {
					var response = responseResult.a;
					if (response.a8) {
						var _v2 = $author$project$Login$Login$getTokenData(response.ca);
						if (!_v2.$) {
							var tokenData = _v2.a;
							var token = A2($author$project$Login$Login$Token, response.ca, tokenData);
							var newModelClearedFields = _Utils_update(
								newModel,
								{p: '', O: ''});
							return _Utils_Tuple3(
								newModelClearedFields,
								$author$project$Login$Login$storeToken(token),
								$author$project$Login$Login$SignedIn(token));
						} else {
							return _Utils_Tuple3(
								_Utils_update(
									newModel,
									{
										t: $elm$core$Maybe$Just('Ocorreu um erro ao entrar com a sua conta')
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Login$Login$NoOp);
						}
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									t: $elm$core$Maybe$Just(response.t)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Login$Login$NoOp);
					}
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							newModel,
							{
								t: $elm$core$Maybe$Just('Usuário ou senha inválidos')
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Login$Login$NoOp);
				}
			case 4:
				return _Utils_Tuple3(
					model,
					$author$project$Login$Login$facebookLogin(0),
					$author$project$Login$Login$NoOp);
			case 5:
				return _Utils_Tuple3(
					model,
					$author$project$Login$Login$googleLogin(0),
					$author$project$Login$Login$NoOp);
			case 6:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Login$Login$NoOp);
			case 7:
				var maybeGoogleToken = msg.a;
				return _Utils_Tuple3(
					model,
					function () {
						if (!maybeGoogleToken.$) {
							var googleToken = maybeGoogleToken.a;
							return A2($author$project$Login$Login$postLogoutGoogle, model.bk, googleToken);
						} else {
							return $author$project$Login$Login$logoutAll(0);
						}
					}(),
					$author$project$Login$Login$NoOp);
			case 8:
				return _Utils_Tuple3(
					model,
					$author$project$Login$Login$logoutAll(0),
					$author$project$Login$Login$NoOp);
			case 9:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Login$Login$LoggedOut);
			case 10:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Login$Login$GoToForgotPassword);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Login$Login$GoToSignUp);
		}
	});
var $author$project$LoginPage$updateLoginOutMsg = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var token = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ca: $elm$core$Maybe$Just(token)
						}),
					A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Home));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ca: $elm$core$Maybe$Nothing}),
					A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login));
			case 3:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					A2($author$project$Route$pushUrl, model.aO, $author$project$Route$SignUp));
		}
	});
var $author$project$LoginPage$update = F2(
	function (msg, model) {
		var loginModel = model.aj;
		var loginMsg = msg;
		var _v1 = A2($author$project$Login$Login$update, loginMsg, loginModel.W);
		var newLoginForm = _v1.a;
		var loginCmd = _v1.b;
		var loginOutMsg = _v1.c;
		var _v2 = A2($author$project$LoginPage$updateLoginOutMsg, loginOutMsg, model);
		var newModel = _v2.a;
		var cmd = _v2.b;
		return _Utils_Tuple2(
			_Utils_update(
				newModel,
				{
					aj: _Utils_update(
						loginModel,
						{W: newLoginForm})
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeL, $author$project$Types$LoginMsg, $elm$core$Basics$identity),
						loginCmd),
						cmd
					])));
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$SignUpPage$isConfirmPasswordInvalid = $elm$core$Basics$neq;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$SignUpPage$isPasswordInvalid = $elm$core$String$isEmpty;
var $author$project$SignUpPage$isUserInvalid = $elm$core$String$isEmpty;
var $author$project$Types$SGotSignUp = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$SignUpMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$signUpEncoder = F2(
	function (user, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(user)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$Api$postSignUp = F3(
	function (apiUrl, user, password) {
		return A4(
			$author$project$Api$post,
			apiUrl + '/auth/signup',
			'',
			$elm$http$Http$jsonBody(
				A2($author$project$Api$signUpEncoder, user, password)),
			A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Types$SignUpMsg, $author$project$Types$SGotSignUp),
				$author$project$Api$responseDecoder));
	});
var $author$project$SignUpPage$update = F2(
	function (msg, model) {
		var signUpModel = model.b2;
		switch (msg.$) {
			case 0:
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: _Utils_update(
								signUpModel,
								{E: user})
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var password = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: _Utils_update(
								signUpModel,
								{O: password})
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var confirmPassword = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: _Utils_update(
								signUpModel,
								{G: confirmPassword})
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var userInvalidText = $author$project$SignUpPage$isUserInvalid(signUpModel.E) ? 'Usuário não pode ser vazio' : '';
				var passwordInvalidText = $author$project$SignUpPage$isPasswordInvalid(signUpModel.O) ? 'Senha não pode ser vazia' : '';
				var confirmPasswordInvalidText = A2($author$project$SignUpPage$isConfirmPasswordInvalid, signUpModel.O, signUpModel.G) ? 'Senhas não conferem' : '';
				var errors = A2(
					$elm$core$List$filter,
					A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
					_List_fromArray(
						[userInvalidText, passwordInvalidText, confirmPasswordInvalidText]));
				return $elm$core$List$isEmpty(errors) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: _Utils_update(
								signUpModel,
								{t: '', _: true})
						}),
					A3($author$project$Api$postSignUp, model.bk, signUpModel.E, signUpModel.O)) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: _Utils_update(
								signUpModel,
								{
									t: A2($elm$core$String$join, '. ', errors)
								})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var responseResult = msg.a;
				var newSignUpModel = _Utils_update(
					signUpModel,
					{_: false});
				var newModel = _Utils_update(
					model,
					{b2: newSignUpModel});
				if (!responseResult.$) {
					var response = responseResult.a;
					return response.b3 ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b2: _Utils_update(
									newSignUpModel,
									{G: '', t: 'Usuário criado com sucesso!', O: '', E: ''})
							}),
						$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b2: _Utils_update(
									newSignUpModel,
									{t: response.t})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b2: _Utils_update(
									newSignUpModel,
									{t: 'Ocorreu um erro ao tentar criar a conta'})
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$pushUrl,
							model.aO,
							$author$project$Route$parseUrl(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var zone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bh: zone}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var url = msg.a;
				var route = $author$project$Route$parseUrl(url);
				var _v2 = A2($author$project$Main$handleUrlChanged, route, model);
				var newModel = _v2.a;
				var cmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{ao: route}),
					cmd);
			case 4:
				var route = msg.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$Route$pushUrl, model.aO, route));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ca: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Login$Login$logout,
								A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)
							])));
			case 6:
				var page1Msg = msg.a;
				return A3(
					$author$project$Helpers$redirectIfToken,
					model.ca,
					A2($author$project$HomePage$update, page1Msg, model),
					_Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)));
			case 7:
				var insertEditMsg = msg.a;
				return A3(
					$author$project$Helpers$redirectIfToken,
					model.ca,
					A2($author$project$InsertEditPage$update, insertEditMsg, model),
					_Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)));
			case 8:
				var loginMsg = msg.a;
				return A2($author$project$LoginPage$update, loginMsg, model);
			case 9:
				var signUpMsg = msg.a;
				return A2($author$project$SignUpPage$update, signUpMsg, model);
			case 10:
				var changePasswordMsg = msg.a;
				return A3(
					$author$project$Helpers$redirectIfToken,
					model.ca,
					A2($author$project$ChangePasswordPage$update, changePasswordMsg, model),
					_Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)));
			case 11:
				return A3(
					$author$project$Helpers$redirectIfToken,
					model.ca,
					function (t) {
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($author$project$Api$getBrokers, model.bk, t.ca),
										$author$project$Helpers$showLoader(0)
									])));
					},
					_Utils_Tuple2(
						model,
						A2($author$project$Route$pushUrl, model.aO, $author$project$Route$Login)));
			default:
				var fileResult = msg.a;
				if (!fileResult.$) {
					var file = fileResult.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3($elm$file$File$Download$bytes, 'corretores.zip', 'application/zip', file),
									$author$project$Helpers$closeAlert(0)
								])));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$Helpers$errorAlert(
							A2($author$project$Types$Alert, 'Erro!', 'Ocorreu um erro ao buscar os corretores')));
				}
		}
	});
var $author$project$Types$CPInputConfirmPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$CPInputNewPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$CPInputPassword = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$CPSubmit = {$: 3};
var $elm$html$Html$article = _VirtualDom_node('article');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Types$GetBrokers = {$: 11};
var $author$project$Types$Logout = {$: 5};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$html$Html$figure = _VirtualDom_node('figure');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Helpers$viewHeader = F2(
	function (txtTitle, hasBack) {
		var backButton = hasBack ? A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$author$project$Route$href($author$project$Route$Home),
					$elm$html$Html$Attributes$class('go-back')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/icons/icon-arrow-left.svg')
						]),
					_List_Nil)
				])) : A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(''),
					$elm$html$Html$Events$onClick($author$project$Types$Logout)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$title('Sair'),
							$elm$html$Html$Attributes$src('/assets/icons/icon-export.svg'),
							$elm$html$Html$Attributes$alt('Sair')
						]),
					_List_Nil)
				]));
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-header')
				]),
			_List_fromArray(
				[
					backButton,
					A2(
					$elm$html$Html$figure,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('/assets/images/logo_centauro_horiz.svg'),
									$elm$html$Html$Attributes$alt('Logo Centauro On')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('m-header__container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(txtTitle)
								])),
							A2(
							$elm$html$Html$nav,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('m-dropdown'),
									A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Menu')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('a-button')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$strong,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Menu')
												])),
											A2(
											$elm$svg$Svg$svg,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
												]),
											_List_fromArray(
												[
													A2(
													$elm$svg$Svg$path,
													_List_fromArray(
														[
															$elm$svg$Svg$Attributes$fill('#073050'),
															$elm$svg$Svg$Attributes$d('M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z')
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('#'),
															$elm$html$Html$Events$onClick($author$project$Types$GetBrokers)
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$src('/assets/icons/icon_person.svg')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$strong,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Corretores')
																]))
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$author$project$Route$href($author$project$Route$ChangePassword)
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$src('/assets/icons/icon-password.svg')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$strong,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Alterar Senha')
																]))
														]))
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('#'),
															$elm$html$Html$Events$onClick($author$project$Types$Logout)
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$img,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$src('/assets/icons/icon_logout.svg')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$strong,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Sair')
																]))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$ChangePasswordPage$viewInput = F6(
	function (txtLabel, inpType, inpPlaceholder, msg, inpValue, isDisabled) {
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(txtLabel)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_(inpType),
									$elm$html$Html$Attributes$placeholder(inpPlaceholder),
									$elm$html$Html$Attributes$spellcheck(false),
									$elm$html$Html$Attributes$required(true),
									$elm$html$Html$Events$onInput(msg),
									$elm$html$Html$Attributes$value(inpValue),
									$elm$html$Html$Attributes$disabled(isDisabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$ChangePasswordPage$view = function (model) {
	var changePasswordModel = model.aa;
	var submitting = changePasswordModel._;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mat p-auth')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('t-form')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$article,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mat-wrapper__container')
							]),
						_List_fromArray(
							[
								A2($author$project$Helpers$viewHeader, 'Alterar senha', true),
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('o-form__container'),
										$elm$html$Html$Events$onSubmit(
										$author$project$Types$ChangePasswordMsg($author$project$Types$CPSubmit))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields')
											]),
										_List_fromArray(
											[
												A6(
												$author$project$ChangePasswordPage$viewInput,
												'Senha atual',
												'password',
												'Digite sua senha atual',
												A2($elm$core$Basics$composeL, $author$project$Types$ChangePasswordMsg, $author$project$Types$CPInputPassword),
												changePasswordModel.O,
												submitting),
												A6(
												$author$project$ChangePasswordPage$viewInput,
												'Nova senha',
												'password',
												'Digite sua nova senha',
												A2($elm$core$Basics$composeL, $author$project$Types$ChangePasswordMsg, $author$project$Types$CPInputNewPassword),
												changePasswordModel.N,
												submitting),
												A6(
												$author$project$ChangePasswordPage$viewInput,
												'Confirmar nova senha',
												'password',
												'Confirme sua nova senha',
												A2($elm$core$Basics$composeL, $author$project$Types$ChangePasswordMsg, $author$project$Types$CPInputConfirmPassword),
												changePasswordModel.G,
												submitting),
												$elm$core$String$isEmpty(changePasswordModel.t) ? $elm$html$Html$text('') : A2(
												$elm$html$Html$strong,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(changePasswordModel.t)
													]))
											])),
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('a-button')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Salvar dados')
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Types$GoTo = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$HChangeCompetenceTrimester = function (a) {
	return {$: 9, a: a};
};
var $author$project$Types$HInputCompetenceYear = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$HomePage$filterComplaints = F2(
	function (competence, complaints) {
		var filteredByYear = (competence.ci > 999) ? A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aw;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ci;
					},
					$elm$core$Basics$eq(competence.ci))),
			complaints) : complaints;
		var filteredByTrimester = (!competence.cc) ? filteredByYear : A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aw;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.cc;
					},
					$elm$core$Basics$eq(competence.cc))),
			filteredByYear);
		return filteredByTrimester;
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$core$List$sortBy = _List_sortBy;
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Types$HConfirmSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$HExport = function (a) {
	return {$: 1, a: a};
};
var $author$project$HomePage$viewComplaint = F2(
	function (submitting, complaint) {
		var notSent = $elm$core$String$isEmpty(complaint.bX);
		var isSubmitting = !_Utils_eq(submitting, $author$project$Types$HNone);
		var getSendTxt = function (exportingId) {
			return _Utils_eq(exportingId, complaint.aK) ? 'Confirmando...' : 'Confirmar envio';
		};
		var getExportTxt = function (exportingId) {
			return _Utils_eq(exportingId, complaint.aK) ? 'Exportando...' : 'Exportar';
		};
		var editTxt = notSent ? 'Editar' : 'Visualizar';
		var _v0 = function () {
			switch (submitting.$) {
				case 0:
					return _Utils_Tuple2('Exportar', 'Confirmar envio');
				case 1:
					var exportingId = submitting.a;
					return _Utils_Tuple2(
						getExportTxt(exportingId),
						'Confirmar envio');
				default:
					var sendingId = submitting.a;
					return _Utils_Tuple2(
						'Exportar',
						getSendTxt(sendingId));
			}
		}();
		var exportingTxt = _v0.a;
		var sendingTxt = _v0.b;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(complaint.aw.cc) + ('/' + $elm$core$String$fromInt(complaint.aw.ci)))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$disabled(isSubmitting),
									$elm$html$Html$Attributes$class('a-button a-button--small'),
									$elm$html$Html$Events$onClick(
									$author$project$Types$GoTo(
										$author$project$Route$Edit(complaint.aK)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(editTxt)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$disabled(isSubmitting),
									$elm$html$Html$Attributes$class('a-button a-button--small'),
									$elm$html$Html$Events$onClick(
									$author$project$Types$HomeMsg(
										$author$project$Types$HExport(complaint.aK)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(exportingTxt)
								])),
							notSent ? A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$disabled(isSubmitting),
									$elm$html$Html$Attributes$class('a-button a-button--small'),
									$elm$html$Html$Events$onClick(
									$author$project$Types$HomeMsg(
										$author$project$Types$HConfirmSent(complaint)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(sendingTxt)
								])) : $elm$html$Html$text('')
						]))
				]));
	});
var $author$project$Types$HCancelConfirm = {$: 3};
var $author$project$Types$HInputSusepProtocol = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$HSubmitSusepProtocol = {$: 5};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$HomePage$viewConfirmModal = F2(
	function (protocol, submitting) {
		var isSubmitting = _Utils_eq(
			submitting,
			$elm$core$Maybe$Just(true));
		var hasError = _Utils_eq(
			submitting,
			$elm$core$Maybe$Just(false));
		var confirmTxt = isSubmitting ? 'Confirmando...' : 'Confirmar';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('swal2-container swal2-center swal2-backdrop-show'),
					A2($elm$html$Html$Attributes$attribute, 'style', 'overflow-y: auto;')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('swal2-popup swal2-modal swal2-show'),
							A2($elm$html$Html$Attributes$attribute, 'role', 'dialog'),
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$attribute, 'tabindex', '-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$form,
							_List_fromArray(
								[
									$elm$html$Html$Events$onSubmit(
									$author$project$Types$HomeMsg($author$project$Types$HSubmitSusepProtocol))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('swal2-header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('swal2-title'),
													A2($elm$html$Html$Attributes$attribute, 'style', 'display: flex;')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Protocolo de recebimento da SUSEP')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('swal2-content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$required(true),
													$elm$html$Html$Attributes$class('swal2-input'),
													A2($elm$html$Html$Attributes$style, 'display', 'flex;'),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HInputSusepProtocol)),
													$elm$html$Html$Attributes$value(protocol),
													$elm$html$Html$Attributes$disabled(isSubmitting)
												]),
											_List_Nil),
											hasError ? A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('swal2-validation-message'),
													$elm$html$Html$Attributes$id('swal2-validation-message'),
													A2($elm$html$Html$Attributes$attribute, 'style', 'margin-left: -20px; margin-right: -20px; display: flex;')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Ocorreu um erro ao confirmar')
												])) : $elm$html$Html$text('')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('swal2-actions')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-label', ''),
													$elm$html$Html$Attributes$class('swal2-confirm swal2-styled'),
													A2($elm$html$Html$Attributes$attribute, 'data-darkreader-inline-border-left', ''),
													A2($elm$html$Html$Attributes$attribute, 'data-darkreader-inline-border-right', ''),
													A2($elm$html$Html$Attributes$attribute, 'style', 'display: inline-block; border-left-color: rgb(29, 89, 146); border-right-color: rgb(29, 89, 146); --darkreader-inline-border-left:#2267a9; --darkreader-inline-border-right:#2267a9;'),
													$elm$html$Html$Attributes$disabled(isSubmitting)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(confirmTxt)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-label', ''),
													$elm$html$Html$Attributes$class('swal2-cancel swal2-styled'),
													A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Events$onClick(
													$author$project$Types$HomeMsg($author$project$Types$HCancelConfirm)),
													$elm$html$Html$Attributes$disabled(isSubmitting)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Cancelar')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$HomePage$view = function (_v0) {
	var homeModel = _v0.k;
	var yearString = (!homeModel.aw.ci) ? '' : $elm$core$String$fromInt(homeModel.aw.ci);
	var trimesterString = (!homeModel.aw.cc) ? '' : A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(homeModel.aw.cc));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('t-form')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$article,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mat-wrapper__container')
					]),
				_List_fromArray(
					[
						A2($author$project$Helpers$viewHeader, 'Lista de reclamações', false),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('o-form__container o-table')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$article,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('m-fields')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields__cols')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Filtrar por ano:')
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$spellcheck(false),
																$elm$html$Html$Attributes$type_('text'),
																$elm$html$Html$Attributes$value(yearString),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HInputCompetenceYear)),
																$elm$html$Html$Attributes$placeholder('Ex: 2020')
															]),
														_List_Nil),
														A2(
														$elm$html$Html$strong,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Este campo é obrigatório')
															]))
													])),
												A2(
												$elm$html$Html$label,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Filtrar por trimestre:')
															])),
														A2(
														$elm$html$Html$select,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$required(true),
																A2($elm$html$Html$Attributes$attribute, 'value', trimesterString),
																$elm$html$Html$Events$onInput(
																A2($elm$core$Basics$composeL, $author$project$Types$HomeMsg, $author$project$Types$HChangeCompetenceTrimester))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Todos')
																	])),
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('01')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('01')
																	])),
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('02')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('02')
																	])),
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('03')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('03')
																	])),
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('04')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('04')
																	]))
															])),
														A2(
														$elm$html$Html$strong,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Este campo é obrigatório')
															]))
													]))
											]))
									])),
								A2(
								$elm$html$Html$table,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('o-table')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$thead,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$tr,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Trimestre de Competência')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(' ')
															]))
													]))
											])),
										A2(
										$elm$html$Html$tbody,
										_List_Nil,
										function () {
											var _v1 = homeModel.A;
											switch (_v1.$) {
												case 0:
													return _List_fromArray(
														[
															A2(
															$elm$html$Html$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$td,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$colspan(3),
																			A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Carregando...')
																		]))
																]))
														]);
												case 2:
													var error = _v1.a;
													return _List_fromArray(
														[
															A2(
															$elm$html$Html$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$td,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$colspan(3),
																			A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(error)
																		]))
																]))
														]);
												default:
													var complaints = _v1.a;
													return $elm$core$List$isEmpty(complaints) ? _List_fromArray(
														[
															A2(
															$elm$html$Html$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$td,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$colspan(3),
																			A2($elm$html$Html$Attributes$style, 'text-align', 'center')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Ainda não existem reclamações')
																		]))
																]))
														]) : A2(
														$elm$core$List$map,
														$author$project$HomePage$viewComplaint(homeModel._),
														$elm$core$List$reverse(
															A2(
																$elm$core$List$sortBy,
																A2(
																	$elm$core$Basics$composeR,
																	function ($) {
																		return $.aw;
																	},
																	function (c) {
																		return (c.ci * 100) + c.cc;
																	}),
																A2($author$project$HomePage$filterComplaints, homeModel.aw, complaints))));
											}
										}())
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('a-button a-button--fab'),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick(
						$author$project$Types$GoTo($author$project$Route$Insert))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('+')
					])),
				function () {
				var _v2 = homeModel._;
				if (_v2.$ === 2) {
					return A2($author$project$HomePage$viewConfirmModal, homeModel.Q, homeModel.P);
				} else {
					return $elm$html$Html$text('');
				}
			}()
			]));
};
var $author$project$Types$IEAddComplaint = {$: 2};
var $author$project$Types$IEChangeCompetenceTrimester = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$IEExport = {$: 29};
var $author$project$Types$IEInputCompetenceYear = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$IESubmit = {$: 30};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$InsertEditPage$selectValueAttr = function (selectValue) {
	return $elm$core$String$isEmpty(selectValue) ? A2($elm$html$Html$Attributes$attribute, 'value', selectValue) : $elm$html$Html$Attributes$value(selectValue);
};
var $author$project$InsertEditPage$viewCompetenceTrimesterDescription = function (trimester) {
	var description = function () {
		switch (trimester) {
			case 1:
				return '1º de janeiro à 31 de março';
			case 2:
				return '1º de abril à 30 de junho';
			case 3:
				return '1º de julho à 30 de setembro';
			case 4:
				return '1º de outubro à 31 de dezembro';
			default:
				return '';
		}
	}();
	return $elm$html$Html$text(' ' + description);
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$InsertEditPage$viewCompetenceTrimesterOptions = F4(
	function (lComplaints, selectedTrimester, year, complaintId) {
		switch (lComplaints.$) {
			case 0:
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value('')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Carregando...')
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value('')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ocorreu um erro ao carregar')
							]))
					]);
			default:
				var complaints = lComplaints.a;
				var thisTrimester = A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.aw;
								},
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.cc;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$fromInt,
										A2($elm$core$String$padLeft, 2, '0')))),
							A2(
								$elm$core$List$filter,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.aK;
									},
									$elm$core$Basics$eq(complaintId)),
								complaints))));
				var existingTrimesters = A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.aw;
						},
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.cc;
							},
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$fromInt,
								A2($elm$core$String$padLeft, 2, '0')))),
					A2(
						$elm$core$List$filter,
						function (c) {
							return _Utils_eq(c.aw.ci, year) && (!_Utils_eq(c.aK, complaintId));
						},
						complaints));
				var trimesters = A2(
					$elm$core$List$filter,
					function (m) {
						var intTrimester = A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(m));
						return _Utils_eq(m, thisTrimester) || ((!A2($elm$core$List$member, m, existingTrimesters)) && (!((year > 999) && A2(
							$elm$core$List$any,
							function (c) {
								return A2(
									$author$project$Helpers$competenceIsBefore,
									{cc: intTrimester, ci: year},
									c.aw) && (!$elm$core$String$isEmpty(c.bX));
							},
							complaints))));
					},
					_List_fromArray(
						['01', '02', '03', '04']));
				return A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$selected(selectedTrimester === ''),
								A2($elm$html$Html$Attributes$attribute, 'value', '')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Selecione')
							])),
					A2(
						$elm$core$List$map,
						function (m) {
							return A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$selected(
										_Utils_eq(selectedTrimester, m)),
										$elm$html$Html$Attributes$value(m)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(m)
									]));
						},
						trimesters));
		}
	});
var $author$project$Types$IEChangeComplainerType = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var $author$project$Types$IEChangeComplaintChannel = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Types$IEChangeComplaintReason = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Types$IEChangeComplaintSalesChannel = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Types$IEChangeComplaintStatus = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Types$IEChangeComplaintSubject = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$Types$IEInputComplainerBirthDate = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Types$IEInputComplainerDocument = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Types$IEInputComplainerName = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Types$IEInputComplainerZipCode = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintAgreement = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintClosingDate = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintDocument = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintLogDescription = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintLogTitle = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintOpeningDate = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintProcess = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Types$IEInputComplaintProtocol = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Types$IERemoveComplaint = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$IEToggleAccordion = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$InsertEditPage$complainerTypes = _List_fromArray(
	[
		_Utils_Tuple2(1, 'Segurado'),
		_Utils_Tuple2(2, 'Terceiro'),
		_Utils_Tuple2(3, 'Beneficiário'),
		_Utils_Tuple2(4, 'Participante'),
		_Utils_Tuple2(5, 'Subscritor de título de capitalização'),
		_Utils_Tuple2(6, 'Corretor'),
		_Utils_Tuple2(7, 'Tomador'),
		_Utils_Tuple2(8, 'Garantido'),
		_Utils_Tuple2(9, 'Estipulante'),
		_Utils_Tuple2(10, 'Proponente'),
		_Utils_Tuple2(11, 'Outros')
	]);
var $author$project$InsertEditPage$complaintChannels = _List_fromArray(
	[
		_Utils_Tuple2(1, 'Carta'),
		_Utils_Tuple2(2, 'E-mail'),
		_Utils_Tuple2(3, 'Telefone'),
		_Utils_Tuple2(4, 'Presencial'),
		_Utils_Tuple2(5, 'Site de Empresa'),
		_Utils_Tuple2(6, 'Outros Canais Diretos'),
		_Utils_Tuple2(7, 'SUSEP'),
		_Utils_Tuple2(8, 'Procon'),
		_Utils_Tuple2(9, 'Detrans'),
		_Utils_Tuple2(10, 'Sincors'),
		_Utils_Tuple2(11, 'Defensorias Públicas'),
		_Utils_Tuple2(12, 'Consorciadas'),
		_Utils_Tuple2(13, 'Redes Sociais'),
		_Utils_Tuple2(14, 'Consumidor.gov.br'),
		_Utils_Tuple2(15, 'Imprensa'),
		_Utils_Tuple2(16, 'Outros Canais Indiretos')
	]);
var $author$project$InsertEditPage$cpfCnpjPattern = '(\\d{3}\\.\\d{3}.\\d{3}-\\d{2})|(\\d{2}\\.\\d{3}\\.\\d{3}\\/\\d{4}-\\d{2})';
var $author$project$InsertEditPage$datePattern = '\\d{2}/\\d{2}/\\d{4}';
var $elm$core$Char$toUpper = _Char_toUpper;
var $author$project$Helpers$capitalize = function (str) {
	return function (_v0) {
		var c = _v0.a;
		var s = _v0.b;
		return A2(
			$elm$core$String$cons,
			$elm$core$Char$toUpper(c),
			s);
	}(
		A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(' ', ''),
			$elm$core$String$uncons(str)));
};
var $author$project$Helpers$monthToNumber = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$Helpers$monthToString = function (month) {
	switch (month) {
		case 0:
			return 'janeiro';
		case 1:
			return 'fevereiro';
		case 2:
			return 'março';
		case 3:
			return 'abril';
		case 4:
			return 'maio';
		case 5:
			return 'junho';
		case 6:
			return 'julho';
		case 7:
			return 'agosto';
		case 8:
			return 'setembro';
		case 9:
			return 'outubro';
		case 10:
			return 'novembro';
		default:
			return 'dezembro';
	}
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.aq, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		az: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		aP: month,
		ci: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).az;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).aP;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ci;
	});
var $author$project$Helpers$weekDayToString = function (day) {
	switch (day) {
		case 6:
			return 'domingo';
		case 0:
			return 'segunda';
		case 1:
			return 'terça';
		case 2:
			return 'quarta';
		case 3:
			return 'quinta';
		case 4:
			return 'sexta';
		default:
			return 'sábado';
	}
};
var $author$project$Helpers$formatDatePattern = F3(
	function (format, time, zone) {
		var year4Num = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
		var weekDayStr = $author$project$Helpers$weekDayToString(
			A2($elm$time$Time$toWeekday, zone, time));
		var secondsNum = A2($elm$time$Time$toSecond, zone, time);
		var seconds2Num = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(secondsNum));
		var monthStr = $author$project$Helpers$monthToString(
			A2($elm$time$Time$toMonth, zone, time));
		var monthNum = $author$project$Helpers$monthToNumber(
			A2($elm$time$Time$toMonth, zone, time));
		var month2Num = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(monthNum));
		var minuteNum = A2($elm$time$Time$toMinute, zone, time);
		var minute2Num = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(minuteNum));
		var hourNum = A2($elm$time$Time$toHour, zone, time);
		var hour2Num = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(hourNum));
		var dayNum = A2($elm$time$Time$toDay, zone, time);
		var day2Num = A3(
			$elm$core$String$padLeft,
			2,
			'0',
			$elm$core$String$fromInt(dayNum));
		return A3(
			$elm$core$String$replace,
			'{yyyy}',
			year4Num,
			A3(
				$elm$core$String$replace,
				'{yy}',
				A2($elm$core$String$dropLeft, 2, year4Num),
				A3(
					$elm$core$String$replace,
					'{MMM}',
					A2(
						$elm$core$String$left,
						3,
						$author$project$Helpers$capitalize(monthStr)),
					A3(
						$elm$core$String$replace,
						'{mmm}',
						A2($elm$core$String$left, 3, monthStr),
						A3(
							$elm$core$String$replace,
							'{MM}',
							$elm$core$String$fromInt(monthNum),
							A3(
								$elm$core$String$replace,
								'{mm}',
								month2Num,
								A3(
									$elm$core$String$replace,
									'{M}',
									$author$project$Helpers$capitalize(monthStr),
									A3(
										$elm$core$String$replace,
										'{m}',
										monthStr,
										A3(
											$elm$core$String$replace,
											'{DDD}',
											A2(
												$elm$core$String$left,
												3,
												$author$project$Helpers$capitalize(weekDayStr)),
											A3(
												$elm$core$String$replace,
												'{ddd}',
												A2($elm$core$String$left, 3, weekDayStr),
												A3(
													$elm$core$String$replace,
													'{DD}',
													$elm$core$String$fromInt(dayNum),
													A3(
														$elm$core$String$replace,
														'{dd}',
														day2Num,
														A3(
															$elm$core$String$replace,
															'{D}',
															$author$project$Helpers$capitalize(weekDayStr),
															A3(
																$elm$core$String$replace,
																'{d}',
																weekDayStr,
																A3(
																	$elm$core$String$replace,
																	'{HH}',
																	$elm$core$String$fromInt(hourNum),
																	A3(
																		$elm$core$String$replace,
																		'{hh}',
																		hour2Num,
																		A3(
																			$elm$core$String$replace,
																			'{II}',
																			$elm$core$String$fromInt(minuteNum),
																			A3(
																				$elm$core$String$replace,
																				'{ii}',
																				minute2Num,
																				A3(
																					$elm$core$String$replace,
																					'{SS}',
																					$elm$core$String$fromInt(secondsNum),
																					A3($elm$core$String$replace, '{ss}', seconds2Num, format))))))))))))))))))));
	});
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$InsertEditPage$logSvg = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill('#073050'),
					$elm$svg$Svg$Attributes$d('M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z')
				]),
			_List_Nil)
		]));
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Attributes$pattern = $elm$html$Html$Attributes$stringProperty('pattern');
var $author$project$InsertEditPage$reasons = _List_fromArray(
	[
		_Utils_Tuple2(201, 'Documentos Obrigatórios (Regulamento, Certificado, Extrado...)'),
		_Utils_Tuple2(202, 'Reclamação do Atendimento - (SAC, Internet, Corretores, Ouvidoria...)'),
		_Utils_Tuple2(203, 'Elogios do Atendimento - (SAC, Internet, Corretores, Ouvidoria...)'),
		_Utils_Tuple2(204, 'Sugestões do Atendimento - (SAC, Internet, Corretores, Ouvidoria...)'),
		_Utils_Tuple2(205, 'Informe de Rendimentos - 2ª via e/ou Retificação'),
		_Utils_Tuple2(206, 'Problemas com venda'),
		_Utils_Tuple2(207, 'Análise de Documentação'),
		_Utils_Tuple2(208, 'Proposta/Emissão'),
		_Utils_Tuple2(209, 'Recusa de contratação'),
		_Utils_Tuple2(210, 'Não reconhecimento da compra'),
		_Utils_Tuple2(211, 'Características do Produto'),
		_Utils_Tuple2(212, 'Prêmio'),
		_Utils_Tuple2(213, 'Faturamento (Empresa)'),
		_Utils_Tuple2(214, 'Não recebimento do Boleto'),
		_Utils_Tuple2(215, 'Cobrança indevida'),
		_Utils_Tuple2(216, 'Débito não processado'),
		_Utils_Tuple2(217, 'Reenquadramento'),
		_Utils_Tuple2(218, 'Alterações contratuais diversas - (Alteração de Beneficiário, Capitais, Coberturas...)'),
		_Utils_Tuple2(219, 'Cancelamento da Apólice'),
		_Utils_Tuple2(220, 'Pagamento de Resgate Seguro Total'),
		_Utils_Tuple2(221, 'Revisão do Cálculo do Pagamento'),
		_Utils_Tuple2(222, 'Documentação'),
		_Utils_Tuple2(223, 'Negativa de Pagamento'),
		_Utils_Tuple2(224, 'Pagamento de Indenização (prazo e valor)'),
		_Utils_Tuple2(225, 'Revisão do Valor da Indenização'),
		_Utils_Tuple2(226, 'Documentação'),
		_Utils_Tuple2(227, 'Negativa de Pagamento'),
		_Utils_Tuple2(228, 'Perícia'),
		_Utils_Tuple2(229, 'Junta Médica'),
		_Utils_Tuple2(230, 'Revisão do Valor do % de Invalidez'),
		_Utils_Tuple2(231, 'Prestador de Serviço (Qualidade, Prazo e Atendimento)'),
		_Utils_Tuple2(232, 'Qualidade do Produto (Cesta Básica, funeral, assistência ao lar)'),
		_Utils_Tuple2(233, 'Reembolso (prazo e valor)'),
		_Utils_Tuple2(234, 'Rendimento da Reserva'),
		_Utils_Tuple2(235, 'Coberturas de Riscos'),
		_Utils_Tuple2(236, 'Rentabilidade e Taxas dos Fundos/Planos'),
		_Utils_Tuple2(237, 'Aporte'),
		_Utils_Tuple2(238, 'Suspensão da contribuição mensal'),
		_Utils_Tuple2(239, 'Migração Interna entre Planos'),
		_Utils_Tuple2(240, 'Migração Interna entre Fundos'),
		_Utils_Tuple2(241, 'Portabilidade Externa (transferência PARA outra Entidade)'),
		_Utils_Tuple2(242, 'Portabilidade Interna (transferência DE outra Entidade)'),
		_Utils_Tuple2(243, 'Pagamento de resgate (prazo e valor)'),
		_Utils_Tuple2(244, 'Pagamento de Risco (Pecúlio, Renda...)'),
		_Utils_Tuple2(245, 'Pagamento de Benefício Aposentadoria'),
		_Utils_Tuple2(246, 'Revisão do Cálculo do Pagamento'),
		_Utils_Tuple2(247, 'Documentação'),
		_Utils_Tuple2(248, 'Negativa de Pagamento')
	]);
var $author$project$InsertEditPage$salesChannels = _List_fromArray(
	[
		_Utils_Tuple2(1, 'Corretor (PJ)'),
		_Utils_Tuple2(2, 'Corretor (PF)'),
		_Utils_Tuple2(3, 'Representante de Seguro Varejista'),
		_Utils_Tuple2(4, 'Representante de Seguro não Varejista'),
		_Utils_Tuple2(5, 'Banco'),
		_Utils_Tuple2(6, 'Correspondente Bancário'),
		_Utils_Tuple2(7, 'Correspondente de Microsseguro'),
		_Utils_Tuple2(8, 'Venda Ativa'),
		_Utils_Tuple2(9, 'Internet'),
		_Utils_Tuple2(10, 'Affinity'),
		_Utils_Tuple2(11, 'Venda Direta'),
		_Utils_Tuple2(12, 'Outros Meios')
	]);
var $author$project$InsertEditPage$statuses = _List_fromArray(
	[
		_Utils_Tuple2(1, 'Procedente'),
		_Utils_Tuple2(2, 'Improcedente'),
		_Utils_Tuple2(3, 'Parcialmente procedente'),
		_Utils_Tuple2(4, 'Pendente de decisão')
	]);
var $author$project$InsertEditPage$subjects = _List_fromArray(
	[
		_Utils_Tuple2(2, 'DPVAT'),
		_Utils_Tuple2(3, 'DPEM'),
		_Utils_Tuple2(4, 'HABITACIONAL'),
		_Utils_Tuple2(22, 'FUNERAL'),
		_Utils_Tuple2(23, 'VIAGEM'),
		_Utils_Tuple2(24, 'PRESTAMISTA'),
		_Utils_Tuple2(25, 'ACIDENTES PESSOAIS'),
		_Utils_Tuple2(26, 'DESEMPREGO/PERDA DE RENDA'),
		_Utils_Tuple2(27, 'EDUCACIONAL'),
		_Utils_Tuple2(28, 'VGBL'),
		_Utils_Tuple2(30, 'DEMAIS PRODUTOS DE SOBREVIVÊNCIA DE PESSOAS'),
		_Utils_Tuple2(31, 'VIDA E DEMAIS PRODUTOS DE PESSOAS')
	]);
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$InsertEditPage$viewInput = F8(
	function (txtLabel, inpType, inpPlaceholder, msg, inpValue, _v0, isDisabled, isRequired) {
		var patternTxt = _v0.a;
		var titleTxt = _v0.b;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$for(txtLabel)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(txtLabel)
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_(inpType),
							$elm$html$Html$Attributes$placeholder(inpPlaceholder),
							$elm$html$Html$Attributes$spellcheck(false),
							$elm$html$Html$Attributes$required(isRequired),
							$elm$html$Html$Events$onInput(msg),
							$elm$html$Html$Attributes$value(inpValue),
							$elm$html$Html$Attributes$disabled(isDisabled),
							$elm$core$String$isEmpty(patternTxt) ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Attributes$pattern(patternTxt),
							$elm$html$Html$Attributes$title(titleTxt)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Este campo é obrigatório')
						]))
				]));
	});
var $author$project$Types$IEShowLog = function (a) {
	return {$: 6, a: a};
};
var $elm$html$Html$em = _VirtualDom_node('em');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$InsertEditPage$textDate = F2(
	function (zone, time) {
		return $elm$html$Html$text(
			A3($author$project$Helpers$formatDatePattern, '{dd}/{mm}/{yyyy}', time, zone));
	});
var $author$project$InsertEditPage$textTime = F2(
	function (zone, time) {
		return $elm$html$Html$text(
			A3($author$project$Helpers$formatDatePattern, '{hh}h{ii}m{ss}s', time, zone));
	});
var $elm$core$String$trim = _String_trim;
var $author$project$InsertEditPage$viewLog = F2(
	function (zone, log) {
		var ellipsisOrNothing = ($elm$core$String$length(log.ab) > 50) ? '...' : '';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('drop-down__activities__col')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h4,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(log.ba)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('drop-down__activities__col__text')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Data:')
								])),
							A2(
							$elm$html$Html$em,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$InsertEditPage$textDate, zone, log.H)
								])),
							$elm$html$Html$text(' às '),
							A2(
							$elm$html$Html$em,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$InsertEditPage$textTime, zone, log.H)
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('drop-down__activities__col__text')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Descrição:')
								])),
							A2(
							$elm$html$Html$em,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									_Utils_ap(
										$elm$core$String$trim(
											A2($elm$core$String$left, 50, log.ab)),
										ellipsisOrNothing))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('a-button a-button--outline'),
									$elm$html$Html$Attributes$type_('button'),
									$elm$html$Html$Events$onClick(
									$author$project$Types$InsertEditMsg(
										$author$project$Types$IEShowLog(log)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('+ Detalhes')
								]))
						]))
				]));
	});
var $author$project$Types$IEChangeComplainerGender = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$InsertEditPage$viewSelectGender = F3(
	function (key, gender, isDisabled) {
		return A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Sexo *')
						])),
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$required(true),
							$author$project$InsertEditPage$selectValueAttr(gender),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeL,
								$author$project$Types$InsertEditMsg,
								$author$project$Types$IEChangeComplainerGender(key))),
							$elm$html$Html$Attributes$disabled(isDisabled)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Selecione')
								])),
							A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('M')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Masculino')
								])),
							A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('F')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Feminino')
								])),
							A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('O')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Não informado')
								]))
						])),
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Este campo é obrigatório')
						]))
				]));
	});
var $author$project$InsertEditPage$viewSelectTuple = F6(
	function (txtLabel, selected, msg, list, showId, isDisabled) {
		var newSelected = _Utils_eq(selected, -1) ? '' : $elm$core$String$fromInt(selected);
		return A2(
			$elm$html$Html$label,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(txtLabel)
						])),
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$required(true),
							$author$project$InsertEditPage$selectValueAttr(newSelected),
							$elm$html$Html$Events$onInput(msg),
							$elm$html$Html$Attributes$disabled(isDisabled)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Selecione')
								])),
						A2(
							$elm$core$List$map,
							function (_v0) {
								var id = _v0.a;
								var element = _v0.b;
								var txt = showId ? ($elm$core$String$fromInt(id) + (' - ' + element)) : element;
								return A2(
									$elm$html$Html$option,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(id))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(txt)
										]));
							},
							list))),
					A2(
					$elm$html$Html$strong,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Este campo é obrigatório')
						]))
				]));
	});
var $author$project$InsertEditPage$viewSelectTupleId = F5(
	function (txtLabel, selected, msg, list, isDisabled) {
		return A6($author$project$InsertEditPage$viewSelectTuple, txtLabel, selected, msg, list, true, isDisabled);
	});
var $author$project$InsertEditPage$viewComplaint = F5(
	function (submitting, readOnly, zone, key, complaint) {
		var complainer = complaint.br;
		var isPJ = $elm$core$String$length(complainer.aA) > 14;
		var isFinished = complaint.b3 !== 4;
		var disableField = submitting || readOnly;
		var accordion = 'accordion-' + $elm$core$String$fromInt(key);
		return A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-accordion__drop-down-menu')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$class('activate'),
							$elm$html$Html$Attributes$id(accordion),
							$elm$html$Html$Attributes$name(accordion),
							$elm$html$Html$Attributes$checked(complaint.X),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeL,
								$author$project$Types$InsertEditMsg,
								$author$project$Types$IEToggleAccordion(key)))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(accordion),
							$elm$html$Html$Attributes$class('menu-title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Show/Hide')
								]))
						])),
					A2(
					$elm$html$Html$header,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Dados da Reclamação')
								])),
							readOnly ? $elm$html$Html$text('') : A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('button'),
									$elm$html$Html$Attributes$class('a-button a-button--small'),
									$elm$html$Html$Events$onClick(
									$author$project$Types$InsertEditMsg(
										$author$project$Types$IERemoveComplaint(key)))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Excluir')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('drop-down')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$article,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Título da Atividade')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$value(complaint.bM),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplaintLogTitle(key))),
															$elm$html$Html$Attributes$required(
															!$elm$core$String$isEmpty(complaint.bL))
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Insira a Atividade')
														])),
													A2(
													$elm$html$Html$textarea,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$spellcheck(false),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplaintLogDescription(key))),
															$elm$html$Html$Attributes$required(
															!$elm$core$String$isEmpty(complaint.bM))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(complaint.bL)
														]))
												]))
										])),
									A2(
									$elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$InsertEditPage$logSvg,
											$elm$html$Html$text('Atividade(s) recente(s)')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('drop-down__activities'),
											$elm$core$List$isEmpty(complaint.ak) ? A2($elm$html$Html$Attributes$style, 'overflow-x', 'hidden') : $elm$html$Html$Attributes$class('')
										]),
									function () {
										var _v0 = complaint.ak;
										if (!_v0.b) {
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$h5,
													_List_fromArray(
														[
															A2($elm$html$Html$Attributes$style, 'margin', '0 auto'),
															A2($elm$html$Html$Attributes$style, 'padding', '20px')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Nenhuma atividade adicionada')
														]))
												]);
										} else {
											var logs = _v0;
											return A2(
												$elm$core$List$map,
												$author$project$InsertEditPage$viewLog(zone),
												$elm$core$List$reverse(
													A2(
														$elm$core$List$sortBy,
														A2(
															$elm$core$Basics$composeR,
															function ($) {
																return $.H;
															},
															function (c) {
																return A3($author$project$Helpers$formatDatePattern, '{yyyy}{mm}{dd}{hh}{ii}{ss}', c, zone);
															}),
														logs)));
										}
									}()),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A8(
											$author$project$InsertEditPage$viewInput,
											'CPF/CNPJ do responsável pela venda *',
											'text',
											'123.456.789-00',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplaintDocument(key)),
											complaint.aA,
											_Utils_Tuple2($author$project$InsertEditPage$cpfCnpjPattern, 'CPF/CNPJ inválido'),
											disableField,
											true)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Situação *',
											complaint.b3,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplaintStatus(key)),
											$author$project$InsertEditPage$statuses,
											disableField)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('m-fields__cols', isFinished)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Data de abertura'),
															A2($elm$html$Html$br, _List_Nil, _List_Nil),
															$elm$html$Html$text('(contato do consumidor com a ouvidoria) *')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$spellcheck(false),
															$elm$html$Html$Attributes$required(true),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplaintOpeningDate(key))),
															$elm$html$Html$Attributes$value(complaint.ay.bV),
															$elm$html$Html$Attributes$max(complaint.ay.bq),
															$elm$html$Html$Attributes$disabled(disableField),
															$elm$html$Html$Attributes$pattern($author$project$InsertEditPage$datePattern),
															$elm$html$Html$Attributes$title('dd/mm/yyyy')
														]),
													_List_Nil),
													A2(
													$elm$html$Html$strong,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Este campo é obrigatório')
														]))
												])),
											isFinished ? A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Data de fechamento'),
															A2($elm$html$Html$br, _List_Nil, _List_Nil),
															$elm$html$Html$text('(demanda finalizada) *')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$spellcheck(false),
															$elm$html$Html$Attributes$required(true),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplaintClosingDate(key))),
															$elm$html$Html$Attributes$value(complaint.ay.bq),
															$elm$html$Html$Attributes$min(complaint.ay.bV),
															$elm$html$Html$Attributes$disabled(disableField),
															$elm$html$Html$Attributes$pattern($author$project$InsertEditPage$datePattern),
															$elm$html$Html$Attributes$title('dd/mm/yyyy')
														]),
													_List_Nil),
													A2(
													$elm$html$Html$strong,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Este campo é obrigatório')
														]))
												])) : $elm$html$Html$text('')
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$for('Número do contrato')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Número do contrato')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$placeholder('12345678912345'),
															$elm$html$Html$Attributes$spellcheck(false),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplaintAgreement(key))),
															$elm$html$Html$Attributes$value(complaint.bi),
															$elm$html$Html$Attributes$disabled(disableField)
														]),
													_List_Nil),
													A2(
													$elm$html$Html$strong,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Este campo é obrigatório')
														]))
												]))
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A8(
											$author$project$InsertEditPage$viewInput,
											'Número do processo SUSEP',
											'text',
											'15414000000000000',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplaintProcess(key)),
											complaint.bW,
											_Utils_Tuple2('', ''),
											disableField,
											false)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A8(
											$author$project$InsertEditPage$viewInput,
											'Número do protocolo *',
											'text',
											'111111111111111',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplaintProtocol(key)),
											complaint.bX,
											_Utils_Tuple2('', ''),
											disableField,
											true)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('m-fields__cols')
										]),
									_List_fromArray(
										[
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Motivo *',
											complaint.bY,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplaintReason(key)),
											$author$project$InsertEditPage$reasons,
											disableField),
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Canal de venda *',
											complaint.b0,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplaintSalesChannel(key)),
											$author$project$InsertEditPage$salesChannels,
											disableField)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Canal de reclamação *',
											complaint.bs,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplaintChannel(key)),
											$author$project$InsertEditPage$complaintChannels,
											disableField)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Assunto *',
											complaint.b6,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplaintSubject(key)),
											$author$project$InsertEditPage$subjects,
											disableField)
										])),
									A2(
									$elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Dados do Reclamante')
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A8(
											$author$project$InsertEditPage$viewInput,
											'Nome *',
											'text',
											'João da Silva',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplainerName(key)),
											complainer.bQ,
											_Utils_Tuple2('', ''),
											disableField,
											true)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A8(
											$author$project$InsertEditPage$viewInput,
											'CPF/CNPJ *',
											'text',
											'123.456.789-00',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplainerDocument(key)),
											complainer.aA,
											_Utils_Tuple2($author$project$InsertEditPage$cpfCnpjPattern, 'CPF/CNPJ inválido'),
											disableField,
											true)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											isPJ ? $elm$html$Html$text('') : A8(
											$author$project$InsertEditPage$viewInput,
											'CEP *',
											'text',
											'12345-678',
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEInputComplainerZipCode(key)),
											complainer.cj,
											_Utils_Tuple2('\\d{5}-\\d{3}', 'O CEP precisa ter 8 dígitos'),
											disableField,
											true)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'm-fields__cols',
													$elm$core$String$length(complainer.aA) <= 14)
												]))
										]),
									_List_fromArray(
										[
											isPJ ? $elm$html$Html$text('') : A3($author$project$InsertEditPage$viewSelectGender, key, complainer.bD, disableField),
											A5(
											$author$project$InsertEditPage$viewSelectTupleId,
											'Tipo *',
											complainer.cd,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Types$InsertEditMsg,
												$author$project$Types$IEChangeComplainerType(key)),
											$author$project$InsertEditPage$complainerTypes,
											disableField)
										])),
									isPJ ? $elm$html$Html$text('') : A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Data de nascimento *')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$spellcheck(false),
															$elm$html$Html$Attributes$required(true),
															$elm$html$Html$Events$onInput(
															A2(
																$elm$core$Basics$composeL,
																$author$project$Types$InsertEditMsg,
																$author$project$Types$IEInputComplainerBirthDate(key))),
															$elm$html$Html$Attributes$value(complainer.bm),
															$elm$html$Html$Attributes$pattern($author$project$InsertEditPage$datePattern),
															$elm$html$Html$Attributes$title('dd/mm/yyyy'),
															$elm$html$Html$Attributes$disabled(disableField)
														]),
													_List_Nil)
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Types$IECloseLog = {$: 7};
var $author$project$InsertEditPage$viewLogModal = F2(
	function (zone, maybeLog) {
		if (maybeLog.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var log = maybeLog.a;
			return A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('o-modal is-active')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$article,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'overflow', 'hidden')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$header,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('button'),
												$elm$html$Html$Attributes$class('a-button a-button--close'),
												$elm$html$Html$Events$onClick(
												$author$project$Types$InsertEditMsg($author$project$Types$IECloseLog))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('x')
											])),
										A2(
										$elm$html$Html$h2,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(log.ba)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('o-modal__container')
									]),
								A2(
									$elm$core$List$cons,
									A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$strong,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Data: ')
													])),
												A2(
												$elm$html$Html$em,
												_List_Nil,
												_List_fromArray(
													[
														A2($author$project$InsertEditPage$textDate, zone, log.H)
													])),
												$elm$html$Html$text(' às '),
												A2(
												$elm$html$Html$em,
												_List_Nil,
												_List_fromArray(
													[
														A2($author$project$InsertEditPage$textTime, zone, log.H)
													]))
											])),
									A2(
										$elm$core$List$map,
										function (description) {
											return A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$em,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(description)
															]))
													]));
										},
										A2($elm$core$String$split, '\n\n', log.ab))))
							]))
					]));
		}
	});
var $author$project$InsertEditPage$view = function (model) {
	var insertEditModel = model.c;
	var homeModel = model.k;
	var complaint = insertEditModel.d;
	var readOnly = !$elm$core$String$isEmpty(complaint.bX);
	var disableField = insertEditModel._ || readOnly;
	var title = $elm$core$String$isEmpty(complaint.aK) ? 'Nova Reclamação' : 'Editar Reclamação';
	var trimesterString = (!complaint.aw.cc) ? '' : A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(complaint.aw.cc));
	var competence = complaint.aw;
	var yearString = (!competence.ci) ? '' : $elm$core$String$fromInt(competence.ci);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mat')
			]),
		_List_fromArray(
			[
				A2($author$project$InsertEditPage$viewLogModal, model.bh, insertEditModel.Z),
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('t-form')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$article,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mat-wrapper__container')
							]),
						insertEditModel.Y ? _List_fromArray(
							[
								$elm$html$Html$text('Carregando...')
							]) : _List_fromArray(
							[
								A2($author$project$Helpers$viewHeader, title, true),
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('o-form__container'),
										$elm$html$Html$Events$onSubmit(
										$author$project$Types$InsertEditMsg($author$project$Types$IESubmit))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('m-fields__cols')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Código FIP')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$type_('text'),
																		$elm$html$Html$Attributes$value(model.bC),
																		$elm$html$Html$Attributes$disabled(true)
																	]),
																_List_Nil)
															])),
														A8(
														$author$project$InsertEditPage$viewInput,
														'Ano de competência *',
														'text',
														'Ex: 2015',
														A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEInputCompetenceYear),
														yearString,
														_Utils_Tuple2('20\\d{2}', 'O ano precisa ser maior que 1999'),
														disableField,
														true),
														A2(
														$elm$html$Html$label,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Trimestre de competência *')
																	])),
																A2(
																$elm$html$Html$select,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$required(true),
																		$author$project$InsertEditPage$selectValueAttr(trimesterString),
																		$elm$html$Html$Events$onInput(
																		A2($elm$core$Basics$composeL, $author$project$Types$InsertEditMsg, $author$project$Types$IEChangeCompetenceTrimester)),
																		$elm$html$Html$Attributes$disabled(disableField)
																	]),
																A4($author$project$InsertEditPage$viewCompetenceTrimesterOptions, homeModel.A, trimesterString, complaint.aw.ci, complaint.aK)),
																A2(
																$elm$html$Html$strong,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Este campo é obrigatório]')
																	])),
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$author$project$InsertEditPage$viewCompetenceTrimesterDescription(complaint.aw.cc)
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields m-fields--sub-fields')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$nav,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('m-accordion')
													]),
												$elm$core$Dict$values(
													A2(
														$elm$core$Dict$map,
														A3($author$project$InsertEditPage$viewComplaint, insertEditModel._, readOnly, model.bh),
														complaint.bv)))
											])),
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2('m-fields__cols', !readOnly)
															]))
													]),
												_Utils_ap(
													readOnly ? _List_Nil : _List_fromArray(
														[
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('a-button a-button--outline'),
																	$elm$html$Html$Attributes$type_('button'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Types$InsertEditMsg($author$project$Types$IEAddComplaint))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('+ Adicionar Reclamação')
																])),
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('a-button')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Salvar dados')
																]))
														]),
													_List_fromArray(
														[
															$elm$core$String$isEmpty(complaint.aK) ? $elm$html$Html$text('') : A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('a-button'),
																	$elm$html$Html$Attributes$type_('button'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Types$InsertEditMsg($author$project$Types$IEExport))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('Exportar')
																]))
														])))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Login$Login$defaultConfig = {ad: false, ae: false, af: false, ag: false, ai: false};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Login$Login$FacebookLogin = {$: 4};
var $author$project$Login$Login$GoogleLogin = {$: 5};
var $author$project$Login$Login$InnerGoToForgotPassword = {$: 10};
var $author$project$Login$Login$InnerGoToSignUp = {$: 11};
var $author$project$Login$Login$InputEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Login$Login$InputPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Login$Login$Login = {$: 2};
var $author$project$Login$Login$viewInput = F8(
	function (txtLabel, inpType, inpPlaceholder, msg, inpValue, isDisabled, isInvalid, errorMessage) {
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('', !isInvalid),
									_Utils_Tuple2('is-invalid', isInvalid)
								])),
							$elm$html$Html$Attributes$for(txtLabel)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_(inpType),
									$elm$html$Html$Attributes$placeholder(inpPlaceholder),
									$elm$html$Html$Attributes$spellcheck(false),
									$elm$html$Html$Attributes$required(true),
									$elm$html$Html$Events$onInput(msg),
									$elm$html$Html$Attributes$value(inpValue),
									$elm$html$Html$Attributes$disabled(isDisabled)
								]),
							_List_Nil),
							function () {
							if (!errorMessage.$) {
								var message = errorMessage.a;
								return A2(
									$elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(message)
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]))
				]));
	});
var $author$project$Login$Login$view = F3(
	function (model, pageConfig, viewHeader) {
		var submitting = model._;
		var hasError = !_Utils_eq(model.t, $elm$core$Maybe$Nothing);
		var disableLoginBtn = A2(
			$elm$core$List$any,
			$elm$core$String$isEmpty,
			_List_fromArray(
				[model.p, model.O])) || submitting;
		var btnLoginTxt = submitting ? A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src('assets/icons/icon_loader.svg')
				]),
			_List_Nil) : $elm$html$Html$text('Entrar');
		var _v0 = pageConfig.ai ? _Utils_Tuple2('email', 'usuario@dominio.com.br') : _Utils_Tuple2('text', 'Usuário');
		var userType = _v0.a;
		var userPlaceholder = _v0.b;
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('t-auth')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$article,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mat-wrapper__container')
						]),
					_List_fromArray(
						[
							A2($elm$html$Html$header, _List_Nil, viewHeader),
							A2(
							$elm$html$Html$form,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('o-form__container'),
									$elm$html$Html$Events$onSubmit($author$project$Login$Login$Login)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$header,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Entrar')
												]))
										])),
									A2(
									$elm$html$Html$article,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('m-fields')
										]),
									_List_fromArray(
										[
											A8($author$project$Login$Login$viewInput, 'Email', userType, userPlaceholder, $author$project$Login$Login$InputEmail, model.p, submitting, hasError, $elm$core$Maybe$Nothing),
											A8($author$project$Login$Login$viewInput, 'Senha', 'password', 'Digite sua senha', $author$project$Login$Login$InputPassword, model.O, submitting, hasError, model.t),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('a-button a-button--small'),
															$elm$html$Html$Attributes$classList(
															_List_fromArray(
																[
																	_Utils_Tuple2('a-button--disabled', disableLoginBtn)
																])),
															$elm$html$Html$Attributes$disabled(disableLoginBtn)
														]),
													_List_fromArray(
														[btnLoginTxt]))
												])),
											pageConfig.ad ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Events$onClick($author$project$Login$Login$FacebookLogin),
															$elm$html$Html$Attributes$class('a-button a-button--facebook')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Entrar com Facebook')
														]))
												])) : $elm$html$Html$text(''),
											pageConfig.af ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Events$onClick($author$project$Login$Login$GoogleLogin),
															$elm$html$Html$Attributes$class('a-button a-button--google')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Entrar com Google')
														]))
												])) : $elm$html$Html$text(''),
											pageConfig.ae ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('#'),
															$elm$html$Html$Events$onClick($author$project$Login$Login$InnerGoToForgotPassword),
															$elm$html$Html$Attributes$class('a-button a-button--inline')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Esqueceu sua senha?')
														]))
												])) : $elm$html$Html$text(''),
											pageConfig.ag ? A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('#'),
															$elm$html$Html$Events$onClick($author$project$Login$Login$InnerGoToSignUp),
															$elm$html$Html$Attributes$class('a-button a-button--inline')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Crie sua conta')
														]))
												])) : $elm$html$Html$text('')
										]))
								]))
						]))
				]));
	});
var $author$project$LoginPage$view = function (model) {
	var loginModel = model.aj;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mat p-auth')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				A2($elm$core$Basics$composeL, $author$project$Types$LoginMsg, $elm$core$Basics$identity),
				A3(
					$author$project$Login$Login$view,
					loginModel.W,
					_Utils_update(
						$author$project$Login$Login$defaultConfig,
						{ag: true}),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$figure,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src('/assets/images/logo_centauro_horiz.svg'),
											$elm$html$Html$Attributes$alt('Logo Centauro On')
										]),
									_List_Nil)
								]))
						])))
			]));
};
var $author$project$Types$SInputConfirmPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$SInputPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$SInputUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$SSignUp = {$: 3};
var $elm$html$Html$Attributes$novalidate = $elm$html$Html$Attributes$boolProperty('noValidate');
var $author$project$SignUpPage$viewInput = F7(
	function (txtLabel, inpType, inpPlaceholder, msg, inpValue, isDisabled, isInvalid) {
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('is-invalid', isInvalid)
								])),
							$elm$html$Html$Attributes$for(txtLabel)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_(inpType),
									$elm$html$Html$Attributes$placeholder(inpPlaceholder),
									$elm$html$Html$Attributes$spellcheck(false),
									$elm$html$Html$Attributes$required(true),
									$elm$html$Html$Events$onInput(msg),
									$elm$html$Html$Attributes$value(inpValue),
									$elm$html$Html$Attributes$disabled(isDisabled)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$SignUpPage$view = function (model) {
	var signUpModel = model.b2;
	var submitting = signUpModel._;
	var hasError = !$elm$core$String$isEmpty(signUpModel.t);
	var btnLoginTxt = submitting ? A2(
		$elm$html$Html$img,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$src('assets/icons/icon_loader.svg')
			]),
		_List_Nil) : $elm$html$Html$text('Criar conta');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mat p-auth')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('t-auth')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$article,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mat-wrapper__container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$header,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$figure,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$img,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$src('/assets/images/logo_centauro_horiz.svg'),
														$elm$html$Html$Attributes$alt('Logo Centauro On')
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$novalidate(true),
										$elm$html$Html$Attributes$class('o-form__container'),
										$elm$html$Html$Events$onSubmit(
										$author$project$Types$SignUpMsg($author$project$Types$SSignUp))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$header,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h1,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Criar conta')
													]))
											])),
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('m-fields')
											]),
										_List_fromArray(
											[
												A7(
												$author$project$SignUpPage$viewInput,
												'Usuário',
												'text',
												'Digite seu usuário',
												A2($elm$core$Basics$composeL, $author$project$Types$SignUpMsg, $author$project$Types$SInputUser),
												signUpModel.E,
												submitting,
												hasError && $author$project$SignUpPage$isUserInvalid(signUpModel.E)),
												A7(
												$author$project$SignUpPage$viewInput,
												'Senha',
												'password',
												'Digite sua senha',
												A2($elm$core$Basics$composeL, $author$project$Types$SignUpMsg, $author$project$Types$SInputPassword),
												signUpModel.O,
												submitting,
												hasError && $author$project$SignUpPage$isPasswordInvalid(signUpModel.O)),
												A7(
												$author$project$SignUpPage$viewInput,
												'Confirmar Senha',
												'password',
												'Confirme sua senha',
												A2($elm$core$Basics$composeL, $author$project$Types$SignUpMsg, $author$project$Types$SInputConfirmPassword),
												signUpModel.G,
												submitting,
												hasError && A2($author$project$SignUpPage$isConfirmPasswordInvalid, signUpModel.G, signUpModel.O)),
												$elm$core$String$isEmpty(signUpModel.t) ? $elm$html$Html$text('') : A2(
												$elm$html$Html$strong,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(signUpModel.t)
													])),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('a-button a-button--small')
															]),
														_List_fromArray(
															[btnLoginTxt]))
													])),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$href('#'),
																$elm$html$Html$Events$onClick(
																$author$project$Types$GoTo($author$project$Route$Login)),
																$elm$html$Html$Attributes$class('a-button a-button--inline')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Voltar para a tela de Login')
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Main$viewNotFound = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Ops! Algo de errado aconteceu! Você tentou acessar um recurso que não existe!')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Types$GoTo($author$project$Route$Home))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Ir para tela inicial')
								]))
						]))
				]))
		]));
var $author$project$Main$view = function (model) {
	var _v0 = function () {
		var _v1 = model.ao;
		switch (_v1.$) {
			case 2:
				return _Utils_Tuple2(
					'Reclamações',
					$author$project$HomePage$view(model));
			case 3:
				return _Utils_Tuple2(
					'Nova reclamação',
					$author$project$InsertEditPage$view(model));
			case 5:
				return _Utils_Tuple2(
					'Editar reclamação',
					$author$project$InsertEditPage$view(model));
			case 0:
				return _Utils_Tuple2(
					'Entrar',
					$author$project$LoginPage$view(model));
			case 1:
				return _Utils_Tuple2(
					'Criar conta',
					$author$project$SignUpPage$view(model));
			case 4:
				return _Utils_Tuple2(
					'Alterar senha',
					$author$project$ChangePasswordPage$view(model));
			default:
				return _Utils_Tuple2('Página não encontrada!', $author$project$Main$viewNotFound);
		}
	}();
	var title = _v0.a;
	var body = _v0.b;
	return {
		bn: _List_fromArray(
			[body]),
		ba: 'Centauro-ON - ' + title
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		bJ: $author$project$Main$init,
		bS: $author$project$Types$UrlChanged,
		bT: $author$project$Types$LinkClicked,
		b8: $elm$core$Basics$always(
			A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Types$LoginMsg, $elm$core$Basics$identity),
				$author$project$Login$Login$subscriptions)),
		ce: $author$project$Main$update,
		cg: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (tokens) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (fipCode) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (currentTime) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (apiUrl) {
									return $elm$json$Json$Decode$succeed(
										{bk: apiUrl, ax: currentTime, bC: fipCode, bb: tokens});
								},
								A2($elm$json$Json$Decode$field, 'apiUrl', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'currentTime', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'fipCode', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'tokens', $elm$json$Json$Decode$string)))(0)}});}(this));